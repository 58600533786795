import promise from "axios";
import {DISPATCHER_ENDPOINT} from "../assets/appConstants";

const client = promise.create();
client.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

class axiosClient {
  static sendPostRequest(url, params = {}, config = null) {
    return client.post(url, params, config);
  }

  static sendToDispatcher(params, config) {
    return axiosClient
      .sendPostRequest(DISPATCHER_ENDPOINT(), params, config)
      .then((res) => {
        if (res && res.status && res.status !== 200) {
          return Promise.reject(res);
        }
        return res;
      });
  }
}

export default axiosClient;
