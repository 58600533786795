import { EventEmitter } from "events";
import assign from "object-assign";
import OnboardStep from "./onboard-step";
import ApiService from "../../services/ApiService";
import {
  getLanguage, supportedLanguages as langMap,
  supportedLanguages,
} from "../../assets/i18h/appTranslation";
import {postMessage} from "../../services/utils";
import {MESSAGES_TYPES} from "../../assets/appConstants";


const localStorageDataKey = "wc_ob_step_data";
const CHANGE_EVENT = "change";
const _stepConfig = {
  password: {
    stepName: "password",
    index: 0,
    data: {
      password: {
        value: "",
        isValid: true,
        minLength: 8,
      },
    },
  },
  name: {
    stepName: "name",
    index: 1,
    data: {
      firstName: {
        value: "",
        isValid: true,
      },
      lastName: {
        value: "",
        isValid: true,
      },
    },
  },
  dateAndGender: {
    stepName: "dateAndGender",
    index: 2,
    data: {
      dob: {
        value: null,
        isValid: true,
      },
      gender: {
        value: 0,
        isValid: true,
      },
      acceptTerms: {
        value: false,
        isValid: true,
        type: "bool",
      },
    },
  },
  done: {
    stepName: "done",
    index: 3,
    data: {
      phone: {
        value: undefined,
        isValid: true,
      },
      email: {
        value: undefined,
        isValid: true,
      },
    },
  },
};

let _onBoardSteps = [];
let _stepIndex = 0;
let _keyboardIsOpen = false;
let _chosenLanguage = 'default';

const OnBoardService = assign({}, EventEmitter.prototype, {
  constants: {
    gender: {
      other: 0,
      male: 1,
      female: 2,
    },
    conuntryCode: "",
  },
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  },
  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },
  emitChange() {
    this.emit(CHANGE_EVENT);
  },
  getCountryCode() {
    return this.constants.conuntryCode;
  },
  initOnboard(accountObj) {
    let lang = accountObj?.language;

    if (!Object.values(langMap).includes(lang)){
      lang = "en";
    }
    this.constants.conuntryCode=lang
    const now = new Date()
    const savedData = localStorage.getItem(localStorageDataKey);
    let dataArray = (savedData) ? JSON.parse(savedData) : null;

    if (dataArray?.expiry > now.getTime()) {
      dataArray = dataArray.data;
      if (dataArray[2].stepData?.dob?.value){
        //format to date object
        dataArray[2].stepData.dob.value = new Date(dataArray[2].stepData.dob.value)
      }
      dataArray.forEach((data) => {
        let _step = new OnboardStep(data.stepIndex, data.stepData);
        _onBoardSteps.push(_step);
      });
    } else {
      Object.keys(_stepConfig).forEach((key, index) => {
        let _step = new OnboardStep(index, _stepConfig[key].data);

        if (key === _stepConfig.dateAndGender.stepName) {
          if (accountObj?.birthDate?.date){
            _step.stepData.dob.value = new Date(accountObj.birthDate.date);
          }
          _step.stepData.gender.value = accountObj.gender;
        }

        if (key === _stepConfig.done.stepName) {
          _step.stepData.phone.value = accountObj.phone;
          _step.stepData.email.value = accountObj.email;
        }

        if (key === _stepConfig.name.stepName) {
          _step.stepData.firstName.value = accountObj.firstName;
          _step.stepData.lastName.value = accountObj.lastName;
        }

        _onBoardSteps.push(_step);
      });
    }
  },
  getOnBoardSteps() {
    return _onBoardSteps;
  },
  getStepIndex() {
    return _stepIndex;
  },
  getStepConfig() {
    return _stepConfig;
  },
  setChosenLanguage(lang) {
    _chosenLanguage = lang;
  },
  getStepData(stepIndex) {
    return _onBoardSteps[stepIndex];
  },
  nextStep() {
    const maxSteps = this.numberOfSteps();
    _stepIndex++;
    if (_stepIndex > maxSteps - 1) {
      _stepIndex = maxSteps - 1;
    }
    this.emitChange();
  },
  numberOfSteps() {
    return Object.keys(_stepConfig).length;
  },
  previousStep() {
    _stepIndex--;
    if (_stepIndex < 0) {
      _stepIndex = 0;
    }
    this.emitChange();
  },
  isLastStep() {
    return _stepIndex === this.numberOfSteps() - 1;
  },
  notify() {
    try {
      const now = new Date()
      const ttl = 60*60; //1 hour
      const strJson = JSON.stringify({
        'expire': now.getTime() + ttl,
        'data': _onBoardSteps
      });
      localStorage.setItem(localStorageDataKey, strJson);
      this.emitChange();
    } catch (e) {
    }
  },
  sendSms(accountId, phone, cb) {
    this.saveOnboard(accountId, true, phone).then(() => {
      cb();
    });
  },
  updatePassword(accountId) {
    const self = this;
    let accountData = {
      password: _onBoardSteps[0].stepData.password.value,
    };
    return ApiService.updateAccountData(accountId, accountData)
      .then((res) => {
        if (res) self.nextStep()
      })
  },
  saveOnboard(accountId, sendLink, phone) {
    const self = this;
    let langKey = getLanguage();
    let lang = {
      key: langKey,
      label: langKey === supportedLanguages.en ? "English" : "Hebrew",
    };

    let accountData = {
      firstName: _onBoardSteps[1].stepData.firstName.value,
      lastName: _onBoardSteps[1].stepData.lastName.value,
      gender: _onBoardSteps[2].stepData.gender.value,
      dob: _onBoardSteps[2].stepData.dob.value,
      tnc: _onBoardSteps[2].stepData.acceptTerms.value,
      email: _onBoardSteps[3].stepData.email.value,
      phone: _onBoardSteps[3].stepData.phone.value,
    };

    //update language to server only if user actively change the default
    if (_chosenLanguage !== 'default') {
      accountData.lang = lang
    }

    if (!sendLink && accountData.phone && accountData.phone.intlNumber) {
      accountData.sendLink = true;
    }

    if (sendLink) {
      accountData.sendLink = true;
      accountData.phone = phone;
    }

    return ApiService.updateAccountData(accountId, accountData)
      .then((res) => {
        if (res) {
          localStorage.removeItem(localStorageDataKey);
          postMessage(MESSAGES_TYPES.LOGIN_SUCCESS, {immediateRedirect: true})
          // self.nextStep();
        }
      });
  },
  setKeyboardState(isOpen) {
    _keyboardIsOpen = isOpen;
    this.emitChange();
  },
  getKeyboardState() {
    return _keyboardIsOpen;
  },
});

export default OnBoardService;
