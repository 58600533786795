const globalConfig = global.wizecareHelpers.globalConfigGetViaUrlSync(process.env.REACT_APP_GLOBAL_CONFIG_URL);
const globalConfigTools = new global.GlobalConfigTools(globalConfig);

// For having access from a console in a browser
global.globalConfigTools = globalConfigTools;

globalConfigTools.defineServiceDiscovery(process.env.REACT_APP_WEB_ENV);

if (!globalConfigTools.serviceDiscovery()) {
  throw new Error(`Can not proceed without defining the current environment.`);
}

export const AUTH_ABSENT_TOKEN_STUB = 'notLoggedIn'; // It must be the same value as the backend constant BearerTokenService::FRONTEND_DOES_NOT_HAVE_TOKEN.

export const APPS = {
  WEB: 3, IOS: 2, ANDROID: 1,
};
export const APPS_URL = globalConfigTools.serviceDiscovery('app');

export const WEBSITE_URL = globalConfigTools.serviceDiscovery('site');

export const LOG_IT_ENDPOINT = `${globalConfigTools.serviceDiscovery('backend')}/vt/log-it.php`
export const DISPATCHER_ENDPOINT = () => {
  const isProdQA = (document.domain.indexOf("qa-ajrpwavu4a") > -1)
  if (isProdQA) {
    return "https://qa-api.wizecare.com/vt/api/dispatcher.php"
  }
  return `${globalConfigTools.serviceDiscovery('backend')}/vt/api/dispatcher.php`;
}

export const PUBLIC_ASSETS_URL = globalConfigTools.serviceDiscovery('storageBaseUrl') + '/public_assets';

export const FIREBASE_CONFIG = () => {
  return globalConfigTools.serviceDiscovery('firebase');
}

export const SEND_MODE_RESET = "OWNRP";

export const PROVIDERS_TYPE = {
  GOOGLE: "google", APPLE: "apple", FACEBOOK: "facebook", SAML: "saml", OTP: "otp",
};

export const CUSTOM_TOKEN_TYPE = {
  INVITE: "invite", RESET: "reset",
};

export const API = {
  UPDATE_ACCOUNT_DETAILS: "accounts/update-account-details",
  RESET_PASSWORD: "accounts/reset-password",
  TRADE_CODE_FOR_CUSTOM_TOKEN: "accounts/trade-code-for-custom-token",
  LOGIN_HELPER: "accounts/login-helper",
  RESEND_OTL: "accounts/resend-otl",
};

export const API_RES_CODE = {
  SUCCESS: 100,
  UNAUTHORIZED: 403,
  ERROR_EXPIRE_CODE: 110,
  ERROR_USER_ALREADY_ONBOARDED: 154,
  PASSWORD_TOO_WEAK: 125,
  LOGIN_FAIL_BAD_CREDENTIALS: 115,
  BAD_RESET_CODE: 124,
};

export const URL_PARAMS = {
  HOST: 'host', //the host who called this app (WordPress, instant VC, ios ect)
  OTL: 'OTL', //one time login code
  RESET_CODE: 'resetCode', //reset password code
  FORGOT_PASSWORD: 'forgot', //for routing
  CUSTOM_TOKEN: 'customToken' //for onboarding
};
export const MOBILE_HOSTS = {
  IOS: 'ios', ANDROID: 'android',
};

export const LOG_SEVERITIES = {
  DEBUG: 100,
  INFO: 200,
  NOTICE: 300,
  WARNING: 400,
  ERROR: 500,
  CRITICAL: 600,
  ALERT: 700,
  EMERGENCY: 800,
};
export const MESSAGES_TYPES = {
  LOGIN_SUCCESS: 'loginSuccess', //good end of process
  ONBOARDING_END_UNSUCCESSFULLY: 'onboardingEndUnsuccessfully', //bad end of process
  PROVIDER_LOGIN: 'providerLogin', //needed for handling native back arrow on mobile
  PROVIDER_LOGIN_END: 'providerLoginEnded',
  HIDE_PRELOADER: 'hidePreloader', //app is ready for user input
  SHOW_PRELOADER: 'showPreloader',
  BACK_HOME: 'backHome',//flow has been reset
  CLOSE: 'close',//close the login app (web close button)
  GO_ONBOARDING: 'goOnboarding',
  LANGUAGE_CHANGE: 'languageChange',
}

export const OTP_EVENTS = {
  SMS_SEND_SUCCESS: "SMS_SEND_SUCCESS",
  SMS_SEND_ERROR: "SMS_SEND_ERROR",
  SMS_VERIFY_SUCCESS: "SMS_VERIFY_SUCCESS",
  SMS_VERIFY_ERROR: "SMS_VERIFY_ERROR",
  SMS_RESEND_SUCCESS: "SMS_RESEND_SUCCESS",
  SMS_RESEND_ERROR: "SMS_RESEND_ERROR",
  OTL_SEND_SUCCESS: "OTL_SEND_SUCCESS",
  OTL_SEND_ERROR: "OTL_SEND_ERROR",
  OTL_RESEND_SUCCESS: "OTL_RESEND_SUCCESS",
  OTL_RESEND_ERROR: "OTL_RESEND_ERROR",
};

export const REDIRECT_TYPES = {
  HOME: 'home',
  FORGOT_PASSWORD: 'forgotPassword',
  RESET_PASSWORD: 'resetPassword',
  ONBOARDING: 'onboarding',
}

