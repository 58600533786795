import React from "react";
import { translate } from "react-translate";
import "../../../assets/stylesheets/date-selector.css";
import moment from "moment";
import { getTranslatedMonths } from "../../../assets/i18h/appTranslation";

class DateFieldSelector extends React.Component {
  constructor(props) {
    super(props);

    let initValue = this.getInitValue(props);

    this.state = {
      listIsOpen: false,
      value: initValue,
    };
    this.openList = this.openList.bind(this);
    this.selectValue = this.selectValue.bind(this);
    this.documentOnClick = this.documentOnClick.bind(this);
    this.selectMonth = this.selectMonth.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.documentOnClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.documentOnClick);
  }

  documentOnClick(e) {
    const paths = e.composedPath()
    let outsideClick = true;
    const { listIsOpen } = this.state;
    const key = this.props.fieldType.id;

    paths.forEach((path) => {
      if (path.className === `field-select ${key}`) {
        outsideClick = false;
      }
    });

    if (outsideClick && listIsOpen) {
      this.setState({
        listIsOpen: false,
      });
    }
  }

  getInitValue(props) {
    let value = null;
    if (props.date && moment.isDate(this.props.date)) {
      switch (props.fieldType.key) {
        case "DD":
          value = null
          break;
        case "MM":
          value = null
          break;
        case "YYYY":
          value = props.date.getFullYear();
          break;
        default:
          break;
      }
    } else {
      switch (props.fieldType.key) {
        case "DD":
          break;
        case "MM":
          break;
        case "YYYY":
          value = props.date;
          break;
        default:
          break;
      }
    }
    return value;
  }

  openList() {
    const _listIsOpen = this.state.listIsOpen;
    this.setState({
      listIsOpen: !_listIsOpen,
    });
  }

  renderField() {
    const { fieldType, types } = this.props;
    let field;
    switch (fieldType) {
      case types.day:
        field = this.renderDays();
        break;
      case types.month:
        field = this.renderMonths();
        break;
      case types.year:
        field = this.renderYears();
        break;
      default:
        break;
    }

    return field;
  }

  renderDays() {
    let options = [];
    const start = 1;
    const end = 31;

    for (let i = start; i <= end; i++) {
      options.push(
        <div onClick={this.selectValue} className="option" key={`day-${i}`}>
          {i}
        </div>
      );

      if (i < end) {
        options.push(<div className="sep" key={`day-sep-${i}`} />);
      }
    }

    return options;
  }

  renderMonths() {
    let options = [];
    const start = 1;
    const end = 12;
    const months = getTranslatedMonths();
    for (let i = start; i <= end; i++) {
      options.push(
        <div
          data-key={i}
          onClick={this.selectMonth}
          className="option"
          key={`day-${i}`}
        >
          {months[i - 1]}
        </div>
      );

      if (i < end) {
        options.push(<div className="sep" key={`day-sep-${i}`} />);
      }
    }

    return options;
  }

  renderYears() {
    let options = [];
    let startYear = new Date().getFullYear() - 4;
    let endYear = 1930;

    for (let i = startYear; i >= endYear; i--) {
      options.push(
        <div onClick={this.selectValue} className="option" key={`day-${i}`}>
          {i}
        </div>
      );

      if (i > endYear) {
        options.push(<div className="sep" key={`day-sep-${i}`} />);
      }
    }

    return options;
  }

  selectValue(e) {
    const { onValueChange, fieldType } = this.props;
    const value = parseInt(e.target.innerText);
    this.setState({
      listIsOpen: false,
      value: value,
    });

    onValueChange(fieldType, value);
  }

  selectMonth(e) {
    const { onValueChange, fieldType } = this.props;
    const monthNum = parseInt(e.target.getAttribute("dataKey"));
    const monthName = e.target.innerText;
    this.setState({
      listIsOpen: false,
      value: monthName,
    });

    onValueChange(fieldType, monthNum);
  }

  getValue() {
    const { fieldType, t } = this.props;
    const { value } = this.state;
    return value ? value : t(`date.selector.${fieldType.key}`);
  }

  isSelectedField() {
    const { fieldType, types } = this.props;
    const { value } = this.state;
    if (fieldType === types.day) {
      return true;
    }
    return !!value;
  }

  render() {
    const { listIsOpen } = this.state;
    const value = this.getValue();
    // const selected = this.isSelectedField();
    return (
      <div className={`select selected`} onClick={this.openList}>
        <div className="value">
          {value}
        </div>
        <div className="v-select" onClick={this.openList}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8.769"
            height="5.663"
            viewBox="0 0 8.769 5.663"
          >
            <g>
              <g>
                <path
                  fill="#5a5a5a"
                  d="M.193 1.742a.655.655 0 0 1 0-.929l.62-.62a.655.655 0 0 1 .929 0l2.642 2.643L7.026.193a.656.656 0 0 1 .93 0l.619.62a.654.654 0 0 1 0 .929L4.847 5.47a.651.651 0 0 1-.926 0z"
                />
              </g>
            </g>
          </svg>
        </div>
        {listIsOpen && (
          <div className="options">
            <div className="options-container">
              <div className="inner-container">
                <div className="fix-cont">{this.renderField()}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default translate("onboarding")(DateFieldSelector);
