import React from "react";
import "../../assets/stylesheets/close-buton.css"
import ApiService from "../../services/ApiService";
import {MESSAGES_TYPES, MOBILE_HOSTS} from "../../assets/appConstants";
import {postMessage} from "../../services/utils";

class CloseButton extends React.Component {
  constructor(props) {
    super(props);
    let shouldRender = true;
    //currently each app manages it own close button.
    shouldRender = false
    let host = ApiService.getAppHost()
    //on mobile don't render the close button
    if (Object.values(MOBILE_HOSTS).indexOf(host) > -1) {
      shouldRender = false
    }
    this.state = {
      shouldRender
    };

  }

  selectedIcon() {
    return (<svg width="17.366" height="17.366" viewBox="0 0 17.366 17.366">
        <g transform="translate(-1531 -195)">
          <rect width="2.377" height="22.182" rx="1.188" transform="rotate(-45 1002.914 -1749.74)" style={{fill:"#bebebe"}}/>
          <rect width="2.377" height="22.182" rx="1.188" transform="rotate(-135 810.322 -211.246)" style={{fill:"#bebebe"}}/>
        </g>
      </svg>

    );
  }

  postCloseMessage() {
    postMessage(MESSAGES_TYPES.CLOSE)
  }

  render() {
    const {shouldRender} = this.state;
    return shouldRender && (
        <div className="close-button" onClick={this.postCloseMessage}>{this.selectedIcon()}</div>
      );
  }
}

export default CloseButton;
