import React from 'react';
import ResendEmailIcon from '../../assets/svg/resend-email-icon';

const ResendWrapper = ({ phoneOrEmail, t }) => (
  <div className="resend-message-wrapper">
    <div className="icon">
      <ResendEmailIcon />
    </div>
    <div className="text">
      <div className="primary-text">{`${t("login.otp.sendlink")} ${phoneOrEmail}`}</div>
      <div className="second-text">
        {t("login.otp.continuelink")}
      </div>
    </div>
  </div>
);

export default ResendWrapper;
