import React from 'react';
import OtpInput from 'react-otp-input';

const OtpInputWrapper = ({ otp, setOtp }) => (
  <div className="input-wrapper" style={{ direction: "ltr" }}>
    <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      separator={<span>-</span>}
      inputStyle={{
        border: "1px solid #42C0B4",
        width: "1.1rem",
        height: "1.1rem",
        margin: "0 0.25rem",
        fontSize: "1.2rem",
        direction: 'ltr !important'
      }}
      inputType='tel'
      renderInput={(props) => <input {...props} />}
      shouldAutoFocus
    />
  </div>
);

export default OtpInputWrapper;
