import React from "react";
import {translate} from "react-translate";
import OnBoardService from "../onboard-service";
import {
  AppleStoreIcon,
  GoogleStoreIcon,
  PhoneIcon,
  RedQuestionMark,
  PhoneEnvelopeIcon,
  RightWhiteArrow,
} from "../../../assets/svg/onboard-icons";
import {supportedLanguages} from "../../../assets/i18h/appTranslation";
import {default as PhoneInput} from 'react-telephone-input/lib/withStyles'
import ApiService, {validatePhone} from "../../../services/ApiService";
import {postMessage} from "../../../services/utils";
import {MESSAGES_TYPES, PUBLIC_ASSETS_URL} from "../../../assets/appConstants";

class OnBoardSmsSender extends React.Component {
  constructor(props) {
    super(props);
    let userPhone = {};
    if (props.stepData.stepData.phone && props.stepData.stepData.phone.value) {
      userPhone = {
        intlNumber: props.stepData.stepData.phone.value.intlNumber,
      };
    }

    this.state = {
      isSent: false,
      isSentError: false,
      sendInProgress: false,
      phone: userPhone,
      showValidation: false,
      defaultCountry: props.language === supportedLanguages.he ? 'il' : 'us',
    };
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.sendSmsMessage = this.sendSmsMessage.bind(this);
    this.sendSmsMessage_CB = this.sendSmsMessage_CB.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
    this.handleGeoLocation();
  }

  handleGeoLocation() {
    ApiService.getGeoLocation().then(info => {
      if (info) {
        if (info.country) {
          this.setState({
            defaultCountry: info.country,
          })
        }
      }
    })
  }

  sendSmsMessage() {
    const {phone} = this.state;
    const {accountId} = this.props;
    const isPhoneValid = validatePhone(phone.lineNumber);
    if (!isPhoneValid) {
      this.setState({
        showValidation: true,
      });
      return;
    }
    this.setState({
      sendInProgress: true,
    });
    OnBoardService.sendSms(accountId, phone, this.sendSmsMessage_CB);
  }

  sendSmsMessage_CB() {
    this.setState({
      isSent: true,
      isSentError: false,
      showValidation: false,
      sendInProgress: false,
    });
  }

  closeWizard() {
    postMessage(MESSAGES_TYPES.LOGIN_SUCCESS, {immediateRedirect: true})
  }

  onPhoneChange(intlNumber, phoneObject) {
    if (intlNumber) {
      let lineNumber = intlNumber.replace('+', '');

      if (lineNumber.startsWith(phoneObject.dialCode)) {
        const len = phoneObject.dialCode.length;
        lineNumber = lineNumber.substring(len);
      }

      if (!lineNumber.startsWith('0')) {
        lineNumber = '0' + lineNumber;
      }

      this.setState({
        phone: {
          intlNumber: intlNumber,
          countryCode: phoneObject.dialCode,
          lineNumber: lineNumber,
        },
        showValidation: false,
      });
    }
  }

  render() {
    const {t, isMobile} = this.props;
    const {showValidation, isSent, defaultCountry, phone} = this.state;
    const flagsPng = `${PUBLIC_ASSETS_URL}/flags.png`
    return (
      <div className="item-col-wrap col-100 flex-direction-col">

        <div className="item-row align-items-flex-start step-form desktop-done" style={!isMobile ? {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
        } : {
          display: 'none',
        }}>
          <div className="item-col-wrap col-60 control-wrap">
            <div className="step-caption done">
              {t("wizard.step.four.title")}
            </div>
            <div className="item-row justify-content-center">
              <div className="control-title done">
                {t("wizard.step.four.subtitle")}
              </div>
            </div>
            {!isSent && (
              <div className="item-row justify-content-center center-row" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <div className="item-col-wrap">
                  <PhoneIcon/>
                </div>
                <div className="send-link" style={{
                  padding: 0,
                  margin: 0,
                }}>
                  {t("wizard.step.four.sendlink")}
                </div>

                <div className="item-col-wrap flex-direction-col">
                  <div className="control">
                    <PhoneInput
                      defaultCountry={defaultCountry}
                      preferredCountries={['us', 'il']}
                      flagsImagePath={flagsPng}
                      onChange={this.onPhoneChange}
                      onBlur={this.onPhoneChange}
                      value={phone.intlNumber}
                      autoFormat={false}
                    />
                    {showValidation && (
                      <>
                        <div className="red-q-mark send-sms">
                          <RedQuestionMark/>
                        </div>
                        <div className="error-msg send-sms">
                          {t("wizard.step.four.send.error")}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="control">
                    <div className="done-btn" onClick={this.sendSmsMessage}>
                      {t("wizard.step.four.send")}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isSent && (
              <div className="item-row send-confirm">
                <div className="item-col-wrap col-100 justify-content-flex-end align-items-flex-start" style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-end',
                }}>
                  <div className="send-link">
                    {t("wizard.step.four.linksent")}
                  </div>
                  <PhoneEnvelopeIcon/>
                </div>
              </div>
            )}
            <div className="item-row flex-direction-col">
              <div className="item-col-wrap done-txt">
                {t("wizard.step.four.txt1")}
              </div>
              <div className="item-col-wrap done-txt">
                {t("wizard.step.four.txt2")}
              </div>
            </div>
            <div className="item-row justify-content-center">
              <div
                className="item-col-wrap prefer-btn"
                onClick={this.closeWizard}
              >
                {t("wizard.step.four.txt5")}
                <div className="arrow-circle">
                  <RightWhiteArrow/>
                </div>
              </div>
            </div>
          </div>
          <div className="item-col-wrap col-35 control-wrap">
            <div className="done-bg"/>
          </div>
        </div>

        {isMobile && <div className="step-form mobile-done">
          <div className="control-wrap">
            <div className="step-caption done">
              {t("wizard.step.four.title")}
            </div>
            <div className="control-title done">
              {t("wizard.step.four.subtitle")}
            </div>
          </div>
          <div className="control-wrap">
            <div className="control">
              <div className="store-icon">
                <a
                  className="store-link"
                  href="https://apps.apple.com/us/app/wizecare/id1455391587"
                  target="_blank"
                  rel='noreferrer'
                >
                  <AppleStoreIcon/>
                </a>
              </div>
            </div>
            <div className="control">
              <div className="store-icon">
                <a
                  className="store-link"
                  href="https://play.google.com/store/apps/details?id=videotherapy.patient&amp;hl=en"
                  target="_blank"
                  rel='noreferrer'
                >
                  <GoogleStoreIcon/>
                </a>
              </div>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

export default translate("onboarding")(OnBoardSmsSender);
