import {getFirebaseUser} from "../../services/firebase";
import React from "react";
import "../../assets/stylesheets/onboard-wizard-modal.css";
import LanguageSelector from "./components/language-selector";
import OnBoardService from "./onboard-service";
import OnBoardStep1 from "./steps/onboard-step1";
import OnBoardStep2 from "./steps/onboard-step2";
import OnBoardStep3 from "./steps/onboard-step3";
import OnBoardStep4 from "./steps/onboard-step4";
import OnBoardStepper from "./components/onboard-stepper";
import { WizeCareWhiteLogo } from "../../assets/svg/onboard-icons";
import { translate } from "react-translate";
import MobileDetect from "mobile-detect";
import ApiService from "../../services/ApiService";
import {LOG_SEVERITIES, MESSAGES_TYPES, MOBILE_HOSTS} from "../../assets/appConstants";
import { supportedLanguages as langMap} from "../../assets/i18h/appTranslation";
import {postMessage} from "../../services/utils";
import SystemPromptService from "../../services/systemPromptService";
import {ALERTS} from "../common/systemAlert";

class OnboardWizardModal extends React.Component {
  constructor(props) {
    super(props);
    postMessage(MESSAGES_TYPES.GO_ONBOARDING)

    const md = new MobileDetect(window.navigator.userAgent);
    let isMobile = !!(md && md.phone());
    const host = ApiService.getAppHost();
    isMobile = isMobile || Object.values(MOBILE_HOSTS).indexOf(host) > -1
    let language = (Object.values(langMap).includes(props.language)) ? props.language : langMap.en

    this.callLoginHelper = true
    this.state = {
      language,
      steps: OnBoardService.getOnBoardSteps(),
      showValidation: false,
      isMobile: isMobile,
      windowHeight: window.innerHeight,
      keyboardIsOpen: false,
    };

    this._onChange = this._onChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {

    //avoid calling twice (see https://stackoverflow.com/a/72072443)
    if (this.callLoginHelper) {
      this.callLoginHelper = false
      getFirebaseUser(this.props.customToken).then((user)=>{
          ApiService.loginHelper(user.uid, user.accessToken)
            .then((res) => {
              const accountObj = res?.accountData
              if (!accountObj) {
                ApiService.logIt("account data is missing user shouldn't be on onboarding", LOG_SEVERITIES.ALERT, res)
                SystemPromptService.showAlert(ALERTS.INVALID_OTL_CODE);
              }
              accountObj.accountId = user.uid
              OnBoardService.initOnboard(accountObj);
              let newState = {
                stepIndex: OnBoardService.getStepIndex(),
              }
              if (this.state.steps[1]) {
                newState.language = accountObj.language;
                newState.accountObj = accountObj;
                let lang = (Object.values(langMap).includes(accountObj.language)) ? accountObj.language : langMap.en
                this.props.setTranslations(lang);
              }
              this.setState(newState)
              postMessage(MESSAGES_TYPES.HIDE_PRELOADER)
              OnBoardService.addChangeListener(this._onChange);
            });

      })
    }
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    OnBoardService.removeChangeListener(this._onChange);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  _onChange() {
    this.setState({
      steps: OnBoardService.getOnBoardSteps(),
      stepIndex: OnBoardService.getStepIndex(),
      keyboardIsOpen: OnBoardService.getKeyboardState(),
    });
  }

  handleKeyDown(e) {
    if (e.code === "Enter") {
      this.nextStep();
    }
  }

  renderCurrentStep(stepIndex) {
    if (stepIndex === undefined) {
      return;
    }

    let step = <></>;
    let stepData = OnBoardService.getStepData(stepIndex);
    const {
      showValidation,
      isMobile,
      windowHeight,
      language,
      accountObj: { accountId, loginData },
    } = this.state;

    switch (stepIndex) {
      case 0:
        step = (
          <OnBoardStep1
            stepData={stepData}
            showValidation={showValidation}
            isMobile={isMobile}
            windowHeight={windowHeight}
          />
        );
        break;
      case 1:
        step = (
          <OnBoardStep2
            stepData={stepData}
            showValidation={showValidation}
            isMobile={isMobile}
            windowHeight={windowHeight}
          />
        );
        break;
      case 2:
        step = (
          <OnBoardStep3
            stepData={stepData}
            showValidation={showValidation}
            isMobile={isMobile}
          />
        );
        break;
      case 3:
        step = (
          <OnBoardStep4
            stepData={stepData}
            showValidation={showValidation}
            closeHandler={this.closeWizard}
            accountId={accountId}
            isMobile={isMobile}
            loginData={loginData}
            language={language}
          />
        );
        break;
      default:
        break;
    }

    return step;
  }

  goBack() {
    OnBoardService.previousStep();
  }

  closeWizard() {
    this.props.handleClose();
  }

  nextStep() {
    const { stepIndex, accountObj } = this.state;
    const stepData = OnBoardService.getStepData(stepIndex);
    if (!stepData.isStepValid()) {
      this.setState({
        showValidation: true,
      });
      OnBoardService.notify();
      return;
    }
    this.setState({
      showValidation: false,
    });
    if (stepIndex === 0) {
      OnBoardService.updatePassword(accountObj.accountId, this.props.t).then(
      );
    } else if (stepIndex === 2) {
      OnBoardService.saveOnboard(accountObj.accountId);
    } else {
      OnBoardService.nextStep();
    }
  }

  setTranslations(lang) {
    this.setState({
      language: lang,
    });
    this.props.setTranslations(lang);
    OnBoardService.setChosenLanguage(lang)
  }

  render() {
    const { language, stepIndex, keyboardIsOpen } = this.state;
    const currentStep = this.renderCurrentStep(stepIndex);
    return (
      <div className="patient-onboard-wizard-modal">
        <div className="patient-onboard-wizard-modal-content">
          <div className="patient-onboard-wizard-modal-body">
            <div className="lang-btn-wrap">
              <LanguageSelector
                language={language}
                switchLang={this.setTranslations.bind(this)}
              />
            </div>
            {/*{stepIndex < 3 && (*/}
            {/*  <div className="back-btn-wrap" onClick={this.goBack}>*/}
            {/*    {stepIndex > 0 && <LeftArrow />}*/}
            {/*  </div>*/}
            {/*)}*/}
            <>
              {currentStep}
              <div
                className={`stepper ${keyboardIsOpen ? "keyboard-open" : ""}`}
              >
                <OnBoardStepper
                  stepIndex={stepIndex}
                  nextStep={this.nextStep}
                />
                <div className="wc-logo">
                  <WizeCareWhiteLogo />
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    );
  }
}

export default translate("onboarding")(OnboardWizardModal);
