import React, {Component} from "react";
import "../../assets/stylesheets/system-alert.css";
import SystemPromptService from "../../services/systemPromptService";
import {translate} from 'react-translate';
import {redirect} from "../../services/utils";
import {REDIRECT_TYPES} from "../../assets/appConstants";
//todo unify with firebase err handling and api service err handling
export const ALERTS = {
  INVALID_OTL_CODE: {
    title: "invalid.reset.code.title",
    content: "invalid.reset.code.message",
    hideCloseBtn: true,
    btnAction: {
      show: true,
      action: ()=>{
        redirect(REDIRECT_TYPES.FORGOT_PASSWORD)
      },
      title: "close"
    }
  },
  GENERIC: {
    title: "general.title",
    content: "general.message",
    btnCloseTitle: "close",
    hideCloseBtn: false,
    btnAction: {
      show: false,
    },
  },
  THERAPIST_ON_MOBILE: {
    title: "general.title",
    content: "therapist.on.mobile.message",
    btnCloseTitle: "close",
    hideCloseBtn: false,
    btnAction: {
      show: false,
    },
  },
  UPDATE_ACCOUNT_FAIL: {
    title: "update.account.fail.title",
    content: "update.account.fail.message",
    btnCloseTitle: "close",
    hideCloseBtn: false,
    btnAction: {
      show: false,
    },
  },
  WEAK_PASSWORD: {
    title: "weak.password.title",
    content: "weak.password.message",
    btnCloseTitle: "close",
    hideCloseBtn: false,
    btnAction: {
      show: false,
    },
  },
}

class SystemAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSystemAlert: false, alert: ALERTS.GENERIC, isModal: false, translateAlertMessage: true
    };
  }

  componentDidMount() {
    SystemPromptService.addChangeListener(this._onChange);
    this.onActionButtonClick = this.onActionButtonClick.bind(this);
  }

  _onChange = () => {
    const alert = SystemPromptService.getAlert()
    const errorCaught = SystemPromptService.getError()
    let message = this.findErrorDescription(errorCaught)
    if (message) {
      alert.content = message
      this.setState({
        translateAlertMessage: false
      })
    }
    this.setState({
      showSystemAlert: SystemPromptService.isAlertToShow(), alert,
    });
  };

  findErrorDescription(errorCaught) {
    if (!errorCaught) return ''
    if (typeof errorCaught === 'string') return errorCaught
    console.error(errorCaught)
    return errorCaught?.description ?? errorCaught?.data?.description ?? errorCaught?.response?.data?.description ?? ''
  }

  closeAlert() {
    SystemPromptService.hideAlert();
  }

  onActionButtonClick() {
    const {
      alert: {btnAction},
    } = this.state;
    btnAction.action();
    this.closeAlert();
  }

  getButtonStyle() {
    const {alert} = this.state;
    if (alert && alert.btnStyle) {
      return alert.btnStyle;
    } else return {};
  }

  render() {
    const {t} = this.props;
    const {showSystemAlert, alert, translateAlertMessage} = this.state;
    const _alertMsg = (translateAlertMessage) ? t(alert.content) : alert.content
    const btnStyle = this.getButtonStyle();
    let hideCloseBtn = alert.hideCloseBtn;
    return (showSystemAlert && (<div className="wc-system-alert-modal">
      <div className="wc-system-alert-modal-paper">
        <div
          className="wc-system-alert-modal-body"
          style={alert.content ? {} : {minHeight: "220px"}}
        >
          <div className="title">{t(alert.title)}</div>
          {alert.content && (<div
            className="text"
            dangerouslySetInnerHTML={{__html: _alertMsg}}
          />)}
          <div className="buttons">
            {alert.btnAction && alert.btnAction.show && (<div
              className="primary"
              onClick={this.onActionButtonClick}
              style={btnStyle}
            >
              {t(alert.btnAction.title)}
            </div>)}
            {!hideCloseBtn && (<div
              className="close-btn"
              onClick={() => {
                if (alert.btnCloseAction) {
                  alert.btnCloseAction();
                }
                this.closeAlert();
              }}
            >
              {t(alert.btnCloseTitle)}
            </div>)}
          </div>
        </div>
      </div>
      <div className="wc-system-alert-modal-overlay"/>
    </div>));
  }
}

export default translate('errors')(SystemAlert);
