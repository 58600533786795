import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import "../assets/stylesheets/main.css";
import LoginModal from "./login/LoginModal";
import ResetPasswordModal from "./password/ResetPasswordModal";
import ForgotPasswordModal from "./password/ForgotPasswordModal";
import SystemAlert, {ALERTS} from "./common/systemAlert";
import OnboardingWizardModal from "./onBoarding/onboarding-wizard-modal";
import {TranslatorProvider} from "react-translate";
import SelectedTranslation, {
  supportedLanguages as langMap,
} from "../assets/i18h/appTranslation";
import NewPasswordConfirmModal from "./password/NewPasswordConfirmModal";
import LinkSentConfirmModal from "./password/LinkSentConfirmModal";
import SpinnerMan from "./common/spinner-man";
import ApiService from "../services/ApiService";
import {CUSTOM_TOKEN_TYPE, LOG_SEVERITIES, MESSAGES_TYPES, URL_PARAMS} from "../assets/appConstants";
import {finishLogin, LoginWithCustomToken} from "../services/firebase";
import SystemPromptService from "../services/systemPromptService";
import {postMessage} from "../services/utils";

class App extends React.Component {
  constructor(props) {
    super(props);

    //<editor-fold desc="binds">
    this.toggleForgotPasswordModal = this.toggleForgotPasswordModal.bind(this);
    this.toggleOnboardingWizard = this.toggleOnboardingWizard.bind(this);
    this.toggleLoginModal = this.toggleLoginModal.bind(this);
    this.switchTranslation = this.switchTranslation.bind(this);
    this.togglePasswordConfirmModal = this.togglePasswordConfirmModal.bind(this);
    this.toggleLinkConfirmModal = this.toggleLinkConfirmModal.bind(this);
    this.toggleResetPasswordModal = this.toggleResetPasswordModal.bind(this);
    //</editor-fold>

    const urlParams = this.getUrlParams()
    const URL = window.location.href
    const userAgent = window.navigator.userAgent


    ApiService.logIt("app.js constructor", LOG_SEVERITIES.DEBUG, {urlParams, userAgent, URL})

    if (window.location.pathname.includes("/reset") && !(URL_PARAMS.RESET_CODE in urlParams)){
      ApiService.logIt("url contain /reset route but reset code parameter is missing", LOG_SEVERITIES.CRITICAL)
    }
    //if supported language presents on query string, use it
    const language = (Object.values(langMap).includes(urlParams?.lang)) ? urlParams.lang : langMap.en
    const customToken = (URL_PARAMS.CUSTOM_TOKEN in urlParams) ? urlParams[URL_PARAMS.CUSTOM_TOKEN] : null

    this.state = {
      isLoginModalOpen: !(URL_PARAMS.OTL in urlParams),
      isOnboardingWizardOpen: false,
      isForgotPasswordModalOpen: false,
      isResetPasswordModalOpen: true,
      isPasswordConfirmOpen: false,
      isLinkConfirmOpen: false,
      translations: SelectedTranslation(language),
      customToken,
      account: {},
      loginData: {},
      language,
      countryCode: "",
      urlParams
    };
  }

  //<editor-fold desc="toggles">
  toggleOnboardingWizard() {
    this.setState({
      isOnboardingWizardOpen: !this.state.isOnboardingWizardOpen,
    });
  }

  toggleForgotPasswordModal() {
    this.setState({
      isForgotPasswordModalOpen: !this.state.isForgotPasswordModalOpen,
    });
  }

  toggleLoginModal() {
    this.setState({
      isLoginModalOpen: !this.state.isLoginModalOpen,
    });
  }

  toggleLinkConfirmModal() {
    this.setState({
      isLinkConfirmOpen: !this.state.isLinkConfirmOpen,
    });
  }

  togglePasswordConfirmModal() {
    this.setState({
      isPasswordConfirmOpen: !this.state.isPasswordConfirmOpen,
    });
  }

  toggleResetPasswordModal() {
    this.setState({
      isResetPasswordModalOpen: !this.state.isResetPasswordModalOpen,
    });
  }

  //</editor-fold>

  componentDidMount() {
    const {urlParams, language} = this.state

    //if reset or invite (OTL) detected, login on background
    if (URL_PARAMS.RESET_CODE in urlParams) this.loginWithResetCode(urlParams[URL_PARAMS.RESET_CODE]);
    if (URL_PARAMS.OTL in urlParams) this.otlLogin(urlParams[URL_PARAMS.OTL]);

    //open forgot password modal
    if (URL_PARAMS.FORGOT_PASSWORD in urlParams) {
      this.toggleResetPasswordModal()
      this.setState({
        isForgotPasswordModalOpen: true, isLoginModalOpen: false
      })
    }

    //if url not contain lang. fetch location
    ApiService.getGeoLocation().then((info) => {
      if (info) {
        let newLang
        if (info.country.indexOf('IL') > -1) {
          newLang = langMap.he;
        }

        newLang = (Object.values(langMap).includes(newLang)) ? newLang : langMap.en

        if (newLang !== language) {
          this.setState({
            translations: SelectedTranslation(newLang),
            language: newLang,
          });
        }

        this.setState({
          countryCode: info.country_code.toLowerCase(),
        });
      }

    })
  }

  getUrlParams() {
    //get all url params to paramObj
    let params = new URLSearchParams(window.location.search);
    let paramObj = {};
    for (let value of params.keys()) {
      paramObj[value] = params.get(value);
    }
    ApiService.setAppHost(paramObj[URL_PARAMS.HOST] ?? "")
    return paramObj
  };

  switchTranslation(lang) {
    this.setState({
      translations: SelectedTranslation(lang), language: lang,
    });
    postMessage(MESSAGES_TYPES.LANGUAGE_CHANGE)
  }

  otlLogin(otlCode) {
    ApiService.logIt("otlLogin called")
    //exchange idToken with custom token using the server
    ApiService.getCustomTokenByCode(otlCode, CUSTOM_TOKEN_TYPE.INVITE)
      .then((res) => {
        if(!res){
          //if broken, show the login modal
          this.toggleLoginModal()
        }
        LoginWithCustomToken(res.customToken).then(() => {
            finishLogin(res);
          }
        )
      })
      .catch(() => {
        this.toggleLoginModal()
        postMessage(MESSAGES_TYPES.ONBOARDING_END_UNSUCCESSFULLY)
      })
  }

  loginWithResetCode(resetCode) {
    if (!resetCode) {
      SystemPromptService.showAlert(ALERTS.INVALID_OTL_CODE);
    } else {
      ApiService.getCustomTokenByCode(resetCode, CUSTOM_TOKEN_TYPE.RESET)
        .then((response) => {
          console.log(response);
          this.setState({
            loginData: response
          })
          LoginWithCustomToken(response.customToken).then(()=>{
            postMessage(MESSAGES_TYPES.HIDE_PRELOADER, {accountType: response.accountType});
          });
        })
    }
  }

  render() {
    const {
      translations,
      isForgotPasswordModalOpen,
      isResetPasswordModalOpen,
      isLoginModalOpen,
      language,
      isPasswordConfirmOpen,
      isLinkConfirmOpen,
      account,
      loginData,
      countryCode,
      customToken
    } = this.state;
    return (
      <Router>
        <div className="App">
          <TranslatorProvider translations={translations}>
            <Routes>
              <Route
                path={"/"}
                element={<LoginModal
                  open={isLoginModalOpen}
                  openPasswordModal={this.toggleForgotPasswordModal}
                  handleClose={this.toggleLoginModal}
                  countryCode={countryCode}
                  language={language}
                  setTranslation={this.switchTranslation}
                />}
              />
              <Route
                path={"/reset"}
                element={<ResetPasswordModal
                  open={isResetPasswordModalOpen}
                  toggleLoginModal={this.toggleLoginModal}
                  language={language}
                  setTranslation={this.switchTranslation}
                  handleConfirmModal={this.togglePasswordConfirmModal}
                  handleClose={this.toggleResetPasswordModal}
                />}
              />
              <Route
                path={"/onboarding"}
                element={<OnboardingWizardModal
                  account={account}
                  handleClose={this.toggleOnboardingWizard}
                  setTranslations={this.switchTranslation}
                  accountId={account.accountId}
                  language={language}
                  customToken={customToken}
                />}
              />
            </Routes>
            <ForgotPasswordModal
              open={isForgotPasswordModalOpen}
              handleClose={this.toggleForgotPasswordModal}
              toggleLoginModal={this.toggleLoginModal}
              language={language}
              setTranslation={this.switchTranslation}
              toggleConfirmModal={this.toggleLinkConfirmModal}
            />
            <SystemAlert/>
            <SpinnerMan/>
            <NewPasswordConfirmModal
              open={isPasswordConfirmOpen}
              loginData={loginData}
            />
            <LinkSentConfirmModal
              open={isLinkConfirmOpen}
              handleClose={this.toggleLinkConfirmModal}
              openForgotPasswordModal={this.toggleForgotPasswordModal}
              openLoginModal={this.toggleLoginModal}
            />
          </TranslatorProvider>
        </div>
      </Router>
    );
  }

}

export default App;
