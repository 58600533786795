import React from "react";
import SystemPromptService from "../../services/systemPromptService";

class SpinnerMan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
    };
  }

  componentDidMount() {
    SystemPromptService.addChangeListener(this._onChange);
  }

  spinnerStyles() {
    return {
      background: `url(${SystemPromptService.spinnerGifPath}) no-repeat`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "40%",
      width: "40%",
      margin: 'auto',
      marginTop: '15%',
      // borderRadius: '16px',
    };
  }

  _onChange = () => {
    this.setState({
      showSpinner: SystemPromptService.isSpinnerToShow(),
    });
  };

  showStyle() {
    return {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999,
      backgroundColor: "rgba(0, 0, 0, 0.74)",
      // borderRadius: '16px',
    }
  }

  render() {
    const {showSpinner} = this.state;

    return (
      showSpinner && (<div
        style={this.showStyle()}
        className="spinner-man"
      >
        <div style={this.spinnerStyles()}/>
      </div>)
    );
  }
}

export default SpinnerMan;
