import React from "react";
import { translate } from "react-translate";
import { RedQuestionMark } from "../../../assets/svg/onboard-icons";
import OnBoardService from "../onboard-service";

class OnBoardStep2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputInFocus: false,
    };

    this.onStepValueChange = this.onStepValueChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    window.addEventListener("resize", this.onWindowResize);
  }

  onStepValueChange(e, fieldName) {
    let { stepData } = this.props;
    let _newData = stepData.stepData;
    _newData[fieldName].value = e.currentTarget.value;

    stepData.setStepData(_newData);
    OnBoardService.notify();
  }

  onFocus() {
    if (this.props.isMobile) {
      OnBoardService.setKeyboardState(true);
      this.setState({
        inputInFocus: true,
      });
    }
  }

  onBlur() {
    if (this.props.isMobile) {
      OnBoardService.setKeyboardState(false);
      this.setState({
        inputInFocus: false,
      });
    }
  }

  onWindowResize() {
    if (this.props.isMobile) {
      if (this.state.inputInFocus) {
        //keyboard closed by user on mobile device
        const windowHeight = this.props.windowHeight;
        if (windowHeight === window.innerHeight) {
          OnBoardService.setKeyboardState(false);
          this.setState({
            inputInFocus: false,
          });
        }
      }
    }
  }

  render() {
    const { t, stepData, showValidation } = this.props;
    const { inputInFocus } = this.state;
    return (
      <div className="item-row onboard-step">
        <div className="item-col-wrap col-100 flex-direction-col">
          <div className={`step-caption ${inputInFocus ? "hide" : ""}`}>
            {t("wizard.step.two.title")}
          </div>
          <div className="step-form">
            <div className="control-wrap">
              <div className="control-title">{t("wizard.step.two.fname")}</div>
              <div className="control">
                <input
                  type="text"
                  value={stepData.stepData.firstName.value}
                  onChange={(e) => {
                    this.onStepValueChange(e, "firstName");
                  }}
                  className="onboard-input"
                  placeholder={t("wizard.step.two.fname.hint")}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                />
                {!stepData.stepData.firstName.isValid && showValidation && (
                  <>
                    <div className="red-q-mark">
                      <RedQuestionMark />
                    </div>
                    <div className="error-msg">
                      {t("wizard.step.two.fname.error")}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="control-wrap">
              <div className="control-title">{t("wizard.step.two.lname")}</div>
              <div className="control">
                <input
                  type="text"
                  value={stepData.stepData.lastName.value}
                  onChange={(e) => {
                    this.onStepValueChange(e, "lastName");
                  }}
                  className="onboard-input"
                  placeholder={t("wizard.step.two.lname.hint")}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                />
                {!stepData.stepData.lastName.isValid && showValidation && (
                  <>
                    <div className="red-q-mark">
                      <RedQuestionMark />
                    </div>
                    <div className="error-msg">
                      {t("wizard.step.two.lname.error")}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate("onboarding")(OnBoardStep2);
