import React from 'react';

const EmailInputWrapper = ({ phoneOrEmail, setPhoneOrEmail, isValid ,t}) => (
  <input
    type="text"
    name="phoneOrEmail"
    value={phoneOrEmail}
    placeholder={t("login.email.error")}
    onChange={(e) => setPhoneOrEmail(e.target.value)}
    className={`gip-input ${!isValid ? 'invalid' : ''}`}
  />
);

export default EmailInputWrapper;
