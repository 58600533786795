import React from 'react';

const ResendOtpSection = ({ resendTimer, resendOtp, resendDisabled, t }) => (
  <div className="resend-otp-section">
    <div className="didnt-get-code-text">
      {t("login.otp.didnt.get.code")}
    </div>
    <div className="countdown-timer">{resendTimer} {t("login.otp.countdown.sec")}</div>
    <div
      onClick={resendTimer === 0 ? resendOtp : undefined}
      className={`resend-btn ${resendDisabled ? 'disabled' : 'clickable'}`}
    >
      {t("login.otp.resend")}
    </div>
  </div>
);

export default ResendOtpSection;