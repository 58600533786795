class OnboardStep {
  stepIndex;
  stepData;

  constructor(stepIndex, stepData) {
    this.stepIndex = stepIndex;
    this.stepData = stepData;
  }

  getIndex() {
    return this.stepIndex;
  }

  getStepData() {
    return this.stepData;
  }

  setIndex(index) {
    this.stepIndex = index;
  }

  setStepData(newData) {
    this.stepData = newData;
    this.isStepValid();
  }

  isStepValid() {
    let _isStepValid = true;
    Object.keys(this.stepData).forEach((key) => {
      let _isKeyValid = true;
      let _value = this.stepData[key].value;

      if (_value == undefined || _value === null || _value.length === 0) {
        _isKeyValid = _isStepValid = false;
      } else if (_value !== undefined) {
        const minLength = this.stepData[key].minLength;
        if (minLength) {
          if (
            _value.length < minLength ||
            !/[A-Z]/.test(_value) ||
            !/[a-z]/.test(_value) ||
            !/[0-9]/.test(_value))
          {
            _isKeyValid = _isStepValid = false;
          }
        }

        const type = this.stepData[key].type;
        if (type === "bool") {
          if (!_value) {
            _isKeyValid = _isStepValid = false;
          }
        }
      }
      this.stepData[key].isValid = _isKeyValid;
    });

    return _isStepValid;
  }
}

export default OnboardStep;
