import React from 'react';
import { translate } from "react-translate";
import mixPanelService from '../../services/mixPanel/mixPanelService';
import { EVENTS } from '../../services/mixPanel/mixPanelEvents';
const ToggleSwitch = ({ toggleOtpPrompt, otpPrompt,t }) => {
  return (
    <div className="toggle-switch">
      <div 
        className={`toggle-option ${!otpPrompt ? 'active' : ''}`} 
        onClick={() =>{ 
          toggleOtpPrompt(true)
        mixPanelService.trackSwitchToggle(EVENTS.USER_AND_PASSWORD)
        }}
      >
        {t("login.otp.userandpassword")}  
      </div>
      <div 
        className={`toggle-option ${otpPrompt ? 'active' : ''}`} 
        onClick={() => {
          toggleOtpPrompt(false)
        mixPanelService.trackSwitchToggle(EVENTS.OTP)
        }}
      >
        {t("login.otp.txt")}
      </div>
    </div>
  );
};

export default translate("login")(ToggleSwitch);
