import axiosClient from "./axiosClient";
import validator from "validator/es";
import {getIdToken} from "./firebase";
import {
  API,
  API_RES_CODE,
  LOG_IT_ENDPOINT,
  LOG_SEVERITIES,
  OTP_EVENTS,
  REDIRECT_TYPES,
  SEND_MODE_RESET
} from "../assets/appConstants";
import SystemPromptService from "./systemPromptService";
import {ALERTS} from "../components/common/systemAlert";
import axios from "axios";
import {redirect} from "./utils";

const ApiService = {
  frontSession: '',
  loginAppHost: 'default',
  appDetails: '4-1.0.0-0',
  getFrontSessionId() {
    //set unique id for tracing
    const LsKey = 'frontSession'
    //save to local storage if not exist
    if (!localStorage.getItem(LsKey)) {
      const rendStr = (Math.random() + 1).toString(36).substring(2)
      localStorage.setItem(LsKey, 'login-' + rendStr)
    }
    //save to memory
    this.frontSession = this.frontSession || localStorage.getItem(LsKey)
    return this.frontSession
  },
  getAppHost() {
    return this.loginAppHost
  },
  setAppHost(host) {
    this.loginAppHost = host || this.loginAppHost
  },
  updateAccountData(accountId, accountData) {
    const params = {
      api: API.UPDATE_ACCOUNT_DETAILS,
      accountId,
      accountData,
    };

    return this.axiosFlow(params)
  },
  sendResetLink(identifier) {
    const params = {
      api: "accounts/reset-password",
      origin: SEND_MODE_RESET,
      email: identifier,
    }
    return this.axiosFlow(params)
  },
  getCustomTokenByCode(code, type) {
    const params = {
      api: API.TRADE_CODE_FOR_CUSTOM_TOKEN,
      code,
      type,
    };
    return this.axiosFlow(params)
  },
  loginHelper(accountId, idToken) {
    const params = {
      api: "accounts/login-helper",
      accountId,
      idToken,
    };
    return this.axiosFlow(params)
  },
  phoneLogin(phone, password) {
    const params = {
      api: "accounts/phone-login",
      phone,
      password,
    };
    return this.axiosFlow(params)
  },
  logOtpEvent(eventType, details = {}) {
    let message;
    const errorMessage = JSON.stringify(details.error);
    let severity = LOG_SEVERITIES.DEBUG;
    switch (eventType) {
      case OTP_EVENTS.SMS_SEND_SUCCESS:
        message = `OTP / SMS sent SUCCESS to ${details.phoneNumber}`;
        break;
      case OTP_EVENTS.SMS_SEND_ERROR:
        severity = LOG_SEVERITIES.WARNING;
        message = `OTP / Error sending SMS to ${details.phoneNumber}: ${details.error}`;
        break;
      case OTP_EVENTS.SMS_VERIFY_SUCCESS:
        message = "OTP / SMS verification successful.";
        break;
      case OTP_EVENTS.SMS_VERIFY_ERROR:
        severity = LOG_SEVERITIES.WARNING;
        message = `OTP / Error verifying SMS: ${details.error}`;
        break;
      case OTP_EVENTS.SMS_RESEND_SUCCESS:
        message = "OTP / Resending SMS SUCCESS";
        break;
      case OTP_EVENTS.SMS_RESEND_ERROR:
        severity = LOG_SEVERITIES.WARNING;
        message = `OTP / Error resending SMS: ${details.error}`;
        break;
      case OTP_EVENTS.OTL_SEND_SUCCESS:
        message = `OTP / OTL sent SUCCESS to ${details.phoneOrEmail}`;
        break;
      case OTP_EVENTS.OTL_SEND_ERROR:
        severity = LOG_SEVERITIES.WARNING;
        message = `OTP / Error sending OTL to ${details.phoneOrEmail}: ${details.error}`;
        break;
      case OTP_EVENTS.OTL_RESEND_SUCCESS:
        message = `OTP / OTL resent SUCCESS to ${details.phoneOrEmail}`;
        break;
      case OTP_EVENTS.OTL_RESEND_ERROR:
        severity = LOG_SEVERITIES.WARNING;
        message = `OTP / Error resending OTL to ${details.phoneOrEmail}: ${details.error}`;
        break;
      default:
        message = `OTP / Unknown OTP event type: ${eventType}`;
    }
    this.logIt(message, severity, details);
  },
  logIt(message, severity = LOG_SEVERITIES.DEBUG, objectData = {}) {
    message = 'LoginApp / ' + message;
    if (window.location.origin.indexOf('localhost') > -1) {
      console.log(message, JSON.stringify(objectData))
    }
    objectData.appHost = this.getAppHost()
    let frontSession = this.getFrontSessionId()
    let appDetails = this.appDetails

    let params = new URLSearchParams();
    params.append('message', message);
    params.append('severity', severity);
    params.append('objectData', JSON.stringify(objectData));
    params.append('metadata', JSON.stringify({frontSession, appDetails}));
    axios.post(LOG_IT_ENDPOINT, params).catch();
  },
  getGeoLocation() {
    return axios.get('https://ipapi.co/json').then((res) => {
      return res.data;
    });
  },
  async axiosFlow(params) {
    SystemPromptService.showSpinner()
    return await new Promise((resolve, reject) => {
      getIdToken()
        .then((IdToken) => {
          const reqConfig = {
            headers: {
              Authorization: `Bearer ${IdToken}`,
              frontSession: this.getFrontSessionId(),
              appDetails: this.appDetails
            },
          }
          axiosClient
            .sendToDispatcher(params, reqConfig)
            .then((res) => {
              resolve(this.processResponse(res))
            })
            .catch((err) => {
              this.handleError(err)
              reject(err)
            })
        })
    })
  },

  sendOneTimeLink(email) {
    const params = {
      api: "accounts/send-one-time-link",
      email: email,
    };
    return this.axiosFlow(params);
  },

  processResponse(response) {
    if (response.data.code === API_RES_CODE.SUCCESS) {
      SystemPromptService.hideSpinner()
      return response.data.data;
    } else {
      this.handleError(response)
      return false
    }
  },
  handleError(error) {
    ApiService.logIt("error caught:" + ApiService.findErrorDescription(error), LOG_SEVERITIES.WARNING, error)

    let alertObj = ALERTS.GENERIC

    let extractError
    const errCode = error?.data?.code ?? error?.response?.data?.code ?? ''
    if (errCode) {
      switch (errCode) {
        case API_RES_CODE.UNAUTHORIZED:
          alertObj.content = "unauthorized.message"
          break
        case API_RES_CODE.ERROR_EXPIRE_CODE:
          alertObj.title = "expire.code.title"
          alertObj.content = "expire.code.message"
          alertObj.hideCloseBtn = true
          alertObj.btnAction = {
            show: true,
            action: () => {
              redirect(REDIRECT_TYPES.FORGOT_PASSWORD)
            },
            title: "close"
          }
          break
        case API_RES_CODE.PASSWORD_TOO_WEAK:
          alertObj.title = "password.too.weak.title"
          alertObj.content = "password.too.weak.message"
          break
        case API_RES_CODE.LOGIN_FAIL_BAD_CREDENTIALS:
          alertObj.title = "login.failed.title"
          alertObj.content = "login.failed.message"
          break
        case API_RES_CODE.BAD_RESET_CODE:
          alertObj = ALERTS.INVALID_OTL_CODE
          break
        case API_RES_CODE.ERROR_USER_ALREADY_ONBOARDED:
          alertObj.title = "code.used.title"
          alertObj.content = "code.used.message"
          alertObj.hideCloseBtn = true
          alertObj.btnAction = {
            show: true,
            action: () => {
              redirect(REDIRECT_TYPES.HOME)
            },
            title: "close"
          }

          break
        default:
          alertObj.description = "server.error"
          extractError = error
      }
    }
    //pass the error only for unknown err codes (prevent "findErrorDescription" to prompt the error from the thrown error
    SystemPromptService.showAlert(alertObj, extractError)
    return {alertObj, extractError}
  },
  findErrorDescription(errorCaught) {
    if (!errorCaught) return ''
    if (typeof errorCaught === 'string') return errorCaught
    return errorCaught?.description ?? errorCaught?.data?.description ?? errorCaught?.response?.data?.description ?? ''
  }

}

export default ApiService;

export const validateEmail = (email) => {
  return validator.isEmail(email);
};

export const validatePhone = (phone) => {
  return validator.isMobilePhone(phone, 'any');
};

export const convertPhoneToMail = (phone) => {
  return phone + "@wizecare.com";
};


