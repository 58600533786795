import React from "react";
import { translate } from "react-translate";
import { MountainIcon } from "../../assets/svg/password-modal-icons";
import {finishLogin} from "../../services/firebase";

class NewPasswordConfirmModal extends React.Component {
  redirectToApp() {
    const { loginData } = this.props;
    finishLogin(loginData);
  }

  render() {
    const { t, open } = this.props;
    return (
      open && (
        <div className={"confirm-modal-wrapper newPassword"}>
          <div className="modal-content">
            <div className="icon">
              <MountainIcon />
            </div>
            <div className="title">{t("confirmation.title")}</div>
            <div className="button" onClick={this.redirectToApp.bind(this)}>
              {t("confirmation.btn")}
            </div>
          </div>
        </div>
      )
    );
  }
}

export default translate("reset")(NewPasswordConfirmModal);
