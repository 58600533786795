import React from "react";
import { translate } from "react-translate";
import { RedQuestionMark } from "../../../assets/svg/onboard-icons";
import OnBoardService from "../onboard-service";

class OnBoardStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputType: "password",
      _show: false,
      inputInFocus: false,
    };
    this.onStepValueChange = this.onStepValueChange.bind(this);
    this.togglePassword = this.togglePassword.bind(this);

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    window.addEventListener("resize", this.onWindowResize);

    this.passwordRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.isMobile) {
      if (this.passwordRef.current) {
        this.passwordRef.current.focus();
      }
    }
  }

  onFocus() {
    if (this.props.isMobile) {
      OnBoardService.setKeyboardState(true);
      this.setState({
        inputInFocus: true,
      });
    }
  }

  onBlur() {
    if (this.props.isMobile) {
      OnBoardService.setKeyboardState(false);
      this.setState({
        inputInFocus: false,
      });
    }
  }

  onWindowResize() {
    if (this.props.isMobile) {
      if (this.state.inputInFocus) {
        //keyboard closed by user on mobile device
        const windowHeight = this.props.windowHeight;
        if (windowHeight === window.innerHeight) {
          OnBoardService.setKeyboardState(false);
          this.setState({
            inputInFocus: false,
          });
        }
      }
    }
  }

  onStepValueChange(e) {
    let { stepData } = this.props;

    stepData.setStepData({
      password: {
        ...stepData.stepData.password,
        value: e.currentTarget.value,
      },
    });
    OnBoardService.notify();
  }

  togglePassword() {
    const { showPassword } = this.state;
    let _show = !showPassword;
    let _inputType = _show ? "text" : "password";
    this.setState({
      inputType: _inputType,
      showPassword: _show,
    });
  }

  render() {
    const { inputType, inputInFocus } = this.state;
    const { t, stepData, showValidation } = this.props;
    return (
      <div className="item-row onboard-step">
        <div className="item-col-wrap col-100 flex-direction-col">
          <div className={`step-caption ${inputInFocus ? "hide" : ""}`}>
            {t("wizard.step.one.title")}
          </div>
          <div className="step-form">
            <div className="control-wrap">
              <div className="control-title">
                {t("wizard.step.one.create.password")}
              </div>
              <div className="control">
                <input
                  ref={this.passwordRef}
                  value={stepData.stepData.password.value}
                  onChange={this.onStepValueChange}
                  type={inputType}
                  className="onboard-input"
                  placeholder={t("wizard.step.one.password.placeholder")}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                />
                {!stepData.stepData.password.isValid && showValidation && (
                  <>
                    <div className="red-q-mark">
                      <RedQuestionMark />
                    </div>
                  </>
                )}
              </div>
              <div
                className={`control-hint ${
                  !stepData.stepData.password.isValid && showValidation
                    ? "error"
                    : ""
                }`}
                dangerouslySetInnerHTML={{
                  __html: t("wizard.step.one.password.hint"),
                }}
              />
              <div className="control show-password">
                <input
                  type="checkbox"
                  id="showPass"
                  onChange={this.togglePassword}
                />
                <label className="control-label" htmlFor="showPass">
                  {t("wizard.step.one.show.password")}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate("onboarding")(OnBoardStep1);
