import React from "react";

function ResendEmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      fill="none"
      viewBox="0 0 45 44"
    >
      <circle cx="20" cy="24" r="20" fill="#42C0B4" fillOpacity="0.12"></circle>
      <g filter="url(#filter0_bdi_1359_9324)" shapeRendering="crispEdges">
        <path
          fill="url(#paint0_linear_1359_9324)"
          d="M37.585 5.138c1.407-.469 2.746.87 2.277 2.277L32.277 30.17c-.493 1.478-2.503 1.675-3.273.32l-4.642-8.164a4.501 4.501 0 00-1.688-1.688l-8.164-4.642c-1.354-.77-1.158-2.78.32-3.273l22.755-7.585z"
        ></path>
        <path
          stroke="url(#paint1_linear_1359_9324)"
          strokeOpacity="0.7"
          strokeWidth="0.375"
          d="M37.585 5.138c1.407-.469 2.746.87 2.277 2.277L32.277 30.17c-.493 1.478-2.503 1.675-3.273.32l-4.642-8.164a4.501 4.501 0 00-1.688-1.688l-8.164-4.642c-1.354-.77-1.158-2.78.32-3.273l22.755-7.585z"
        ></path>
      </g>
      <path
        fill="#42C0B4"
        fillRule="evenodd"
        d="M39.294 5.706c.405.405.6 1.02.39 1.65L32.1 30.111c-.441 1.324-2.241 1.5-2.931.287l-4.643-8.165a4.685 4.685 0 00-.76-.998l15.53-15.53z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#003057"
        strokeWidth="1.5"
        d="M37.585 5.138c1.407-.469 2.746.87 2.277 2.277L32.277 30.17c-.493 1.478-2.503 1.675-3.273.32l-4.642-8.164a4.501 4.501 0 00-1.688-1.688l-8.164-4.642c-1.354-.77-1.158-2.78.32-3.273l22.755-7.585z"
      ></path>
      <path
        stroke="#003057"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M27.179 17.822l-3 3M13.667 26L11 28.667M13.667 30L11 32.667"
      ></path>
      <defs>
        <filter
          id="filter0_bdi_1359_9324"
          width="34.733"
          height="34.733"
          x="9.412"
          y="0.855"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feGaussianBlur
            in="BackgroundImageFix"
            stdDeviation="1"
          ></feGaussianBlur>
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1359_9324"
          ></feComposite>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.1 0"></feColorMatrix>
          <feBlend
            in2="effect1_backgroundBlur_1359_9324"
            result="effect2_dropShadow_1359_9324"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1359_9324"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.231373 0 0 0 0 0.407843 0 0 0 0 1 0 0 0 0.38 0"></feColorMatrix>
          <feBlend in2="shape" result="effect3_innerShadow_1359_9324"></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_1359_9324"
          x1="1.25"
          x2="56.75"
          y1="-8"
          y2="41.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35938A"></stop>
          <stop offset="0.505" stopColor="#8EF9EF" stopOpacity="0.5"></stop>
          <stop offset="1" stopColor="#6FFFF1" stopOpacity="0.8"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_1359_9324"
          x1="11.75"
          x2="38"
          y1="8.5"
          y2="31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0.15"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0.44"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ResendEmailIcon;
