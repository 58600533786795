export const EVENTS = {
    LOGIN_SUCCESS: 'Login App - Login - Success',
    LOGIN_FAIL: 'Login App - Login - Fail',
    SHOW_PASSWORD: 'Login App - Show Password',
    FORGOT_PASSWORD: 'Login App - Forgot Password',
    GOOGLE_SIGNIN_SUCCESS: 'Google Sign-In - Sign-In - Success',
    GOOGLE_SIGNIN_FAIL: 'Google Sign-In - Sign-In - Fail',
    SSO_SUCCESS: 'SSO - Sign-In - Success',
    SSO_FAIL: 'SSO - Sign-In - Fail',
    SWITCH_TOGGLE: 'Login App - Switch Toggle - ',
    OTP_SUCCESS: 'OTP - Sent Code - Success',
    OTP_FAIL: 'OTP - Sent Code - Fail',
    RESEND_CODE: 'Resend Code',
    USER_ON_HOLD: 'Login App - User On Hold (Too many requests)',
    CHANGE_LANGUAGE: 'Login App - Change Language',
    OTL_SUCCESS: 'OTL - Sent Link - Success',
    OTL_FAIL: 'OTL -Sent Link - Fail',
    OTP :'OTP',
    USER_AND_PASSWORD:'User and password',
};
