import React from "react";
import { translate } from "react-translate";
import {
  PhoneWhiteIcon,
  EnvelopeIcon,
  AppleStoreIcon,
  GoogleStoreIcon,
  RightWhiteArrow,
} from "../../../assets/svg/onboard-icons";
import {postMessage} from "../../../services/utils";
import {MESSAGES_TYPES} from "../../../assets/appConstants";

class OnBoardDoneStep extends React.Component {
  constructor(props) {
    super(props);
    this.closeWizard = this.closeWizard.bind(this);
  }


  closeWizard() {
    postMessage(MESSAGES_TYPES.LOGIN_SUCCESS, {immediateRedirect: true})
  }

  render() {
    const { t, hasEmail, isMobile } = this.props;
    return (
      <div className="item-col-wrap col-100 flex-direction-col">
        <div
          className={`step-form ${
            isMobile ? "mobile-view-hide" : "done-step-body"
          }`}
        >
          <div className="item-col-wrap col-50 control-wrap">
            <div className="item-row justify-content-center align-items-center">
              <div className="done-icon">
                <PhoneWhiteIcon />
              </div>
              <div className="done-icon">
                <EnvelopeIcon />
              </div>
            </div>
            <div className="item-row flex-direction-col">
              <div className="item-col-wrap done-txt">
                {hasEmail && t("wizard.step.four.txt3")}
                {!hasEmail && t("wizard.step.four.txt4")}
              </div>
              <div className="item-col-wrap done-txt">
                {t("wizard.step.four.txt2")}
              </div>
            </div>
            <div className="item-row justify-content-center">
              <div
                className="item-col-wrap prefer-btn"
                onClick={this.closeWizard}
              >
                {t("wizard.step.four.txt5")}
                <div className="arrow-circle">
                  <RightWhiteArrow />
                </div>
              </div>
            </div>
          </div>
          <div className="item-col-wrap col-50 control-wrap">
            <div className="done-bg" />
          </div>
        </div>
        <div
          className={`item-row flex-direction-col step-form ${
            isMobile ? "" : "mobile-view-hide"
          }`}
        >
          <div className="control-wrap">
            <div className="step-caption">{t("wizard.step.four.title")}</div>
          </div>
          <div className="control-wrap">
            <div className="control-title done">
              {t("wizard.step.four.subtitle")}
            </div>
          </div>
          <div className="control-wrap">
            <div className="control">
              <div className="store-icon">
                <a
                  className="store-link"
                  href="https://apps.apple.com/us/app/wizecare/id1455391587"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AppleStoreIcon />
                </a>
              </div>
            </div>
            <div className="control">
              <div className="store-icon">
                <a
                  className="store-link"
                  href="https://play.google.com/store/apps/details?id=videotherapy.patient&amp;hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <GoogleStoreIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate("onboarding")(OnBoardDoneStep);
