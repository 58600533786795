import React from "react";
import { translate } from "react-translate";
import "../../../assets/stylesheets/onboard-stepper.css";
import OnBoardService from "../onboard-service";

class OnBoardStepper extends React.Component {
  constructor(props) {
    super(props);

    this.gotoNextStep = this.gotoNextStep.bind(this);
  }

  gotoNextStep() {
    this.props.nextStep();
  }

  renderStepsCircles() {
    const { stepIndex } = this.props;
    const steps = OnBoardService.getStepConfig();
    let stepCircles = [];
    Object.keys(steps).forEach((key, index) => {
      const selected = stepIndex === index;
      stepCircles.push(
        <div
          className={`step-circle-btn ${selected ? "selected" : ""}`}
          key={key}
        />
      );
    });

    return stepCircles;
  }

  nextBtnCaption() {
    const { t, stepIndex } = this.props;
    const steps = OnBoardService.getStepConfig();
    let isSubmitStep = false;

    const stepCount = Object.keys(steps).length;

    if (stepIndex === stepCount - 2) {
      isSubmitStep = true;
    }

    return OnBoardService.isLastStep()
      ? t("wizard.step.done")
      : isSubmitStep
      ? t("wizard.step.submit")
      : t("wizard.step.next");
  }

  render() {
    const stepCircles = this.renderStepsCircles();
    const isLastStep = OnBoardService.isLastStep();

    return (
      !isLastStep && (
        <div className="onboard-stepper">
          <div className="item-row">
            <div className="item-col-wrap">
              <div className="next-step" onClick={this.gotoNextStep}>
                {this.nextBtnCaption()}
              </div>
            </div>
          </div>
          <div className="item-row justify-content-center">
            <div className="item-col-wrap">{stepCircles}</div>
          </div>
        </div>
      )
    );
  }
}

export default translate("onboarding")(OnBoardStepper);
