import ApiService from "./ApiService";
import {LOG_SEVERITIES, MESSAGES_TYPES, REDIRECT_TYPES, URL_PARAMS} from "../assets/appConstants";

export const postMessage = (message, value = {}) => {
  ApiService.logIt("posting to parent: " + message, LOG_SEVERITIES.DEBUG, value)
  // if (!Object.keys(value).length) {value = 'value'}
  window.parent.postMessage({message, value}, "*");
}

export const redirect = (redirectType, params = {}) => {

  let route = '/'
  switch (redirectType) {
    case REDIRECT_TYPES.HOME:
      postMessage(MESSAGES_TYPES.BACK_HOME)
      break;
    case REDIRECT_TYPES.FORGOT_PASSWORD:
      params.forgot = '1'
      break;
    case REDIRECT_TYPES.RESET_PASSWORD:
      route = '/reset';
      break;
    case REDIRECT_TYPES.ONBOARDING:
      route = '/onboarding';
      break;
    default:
      ApiService.logIt("Invalid redirect type", LOG_SEVERITIES.CRITICAL)
      break;
  }

  let paramsString = '?'
  params.host = ApiService.getAppHost();
  for (const property in params) {
    if (property !== URL_PARAMS.OTL && property !== URL_PARAMS.RESET_CODE){
      paramsString +=`${property}=${params[property]}&`
    }
  }
  ApiService.logIt("Redirecting to: " + route + paramsString, LOG_SEVERITIES.DEBUG)
  window.location.replace(route + paramsString);

}
