import React from "react";
import {translate} from "react-translate";
import {ArrowIcon} from "../../assets/svg/password-modal-icons";
import { MESSAGES_TYPES} from "../../assets/appConstants";
import {postMessage} from "../../services/utils";

class LinkSentConfirmModal extends React.Component {
  render() {
    const {t, open} = this.props;
    return (
      open && (
        <div className={"confirm-modal-wrapper linkSent"}>
          <div className="modal-content">
            <div className="icon">
              <ArrowIcon/>
            </div>
            <div className="title">{t("confirmation.title")}</div>
            <div className="subtitle">{t("confirmation.subtitle")}</div>
            <div
              className="button"
              onClick={() => {
                this.props.handleClose();
                this.props.openLoginModal();
                postMessage(MESSAGES_TYPES.BACK_HOME)
              }}
            >
              {t("confirmation.btn")}
            </div>
            <div className="alt-button" onClick={() => {
              this.props.handleClose();
              this.props.openForgotPasswordModal();
            }}>{t("confirmation.alt.btn")}
            </div>
          </div>
        </div>
      )
    );
  }
}

export default translate("forgot")(LinkSentConfirmModal);
