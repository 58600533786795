import React from "react";
import "../../../assets/stylesheets/date-selector.css";
import DateFieldSelector from "./date-field-selector";
import moment from "moment";

class DateSelector extends React.Component {
  fieldType = {
    day: {
      key: "DD",
      id: "day",
      value: null,
    },
    month: {
      key: "MM",
      id: "month",
      value: null,
    },
    year: {
      key: "YYYY",
      id: "year",
      value: null,
    },
  };

  constructor(props) {
    super(props);

    this.initValue();

    this.onValueChange = this.onValueChange.bind(this);
  }

  initValue() {
    if (this.props.date) {
      if (moment.isDate(this.props.date)) {
        this.fieldType.day.value = this.props.date.getDate();
        this.fieldType.month.value = this.props.date.getMonth() + 1;
        this.fieldType.year.value = this.props.date.getFullYear();
      } else {
        this.fieldType.year.value = this.props.date;
      }
    }
  }

  onValueChange(fieldType, value) {
    this.fieldType[fieldType.id].value = value;
    this.updateParent();
  }

  updateParent() {
    let d = this.fieldType.day.value;
    let m = this.fieldType.month.value;
    let y = this.fieldType.year.value;

    if (d && m && y) {
      const date = new Date(y, m - 1, d);
      this.props.onStepValueChange({ currentTarget: { value: date } }, "dob");
    } else if (y) {
      this.props.onStepValueChange({ currentTarget: { value: y } }, "dob");
    }
  }

  render() {
    const { date } = this.props;
    return (
      <div className="date-selector">
        {/*<div className="field-select day">*/}
        {/*  <DateFieldSelector*/}
        {/*    date={date}*/}
        {/*    fieldType={this.fieldType.day}*/}
        {/*    onValueChange={this.onValueChange}*/}
        {/*    types={this.fieldType}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className="field-select month">*/}
        {/*  <DateFieldSelector*/}
        {/*    date={date}*/}
        {/*    fieldType={this.fieldType.month}*/}
        {/*    onValueChange={this.onValueChange}*/}
        {/*    types={this.fieldType}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="field-select year">
          <DateFieldSelector
            date={date}
            fieldType={this.fieldType.year}
            onValueChange={this.onValueChange}
            types={this.fieldType}
          />
        </div>
      </div>
    );
  }
}

export default DateSelector;
