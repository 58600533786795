import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputWrapper = ({ countryCode, phoneOrEmail, onInputChange }) => (
  <div className="phone-input-wrapper">
    <PhoneInput
      style={{ height: '40px', width: '300px' }}
      country={countryCode}
      value={phoneOrEmail}
      preferredCountries={['il', 'us']}
      onChange={onInputChange}
      countryCodeEditable={false}
      enableSearch
      disableSearchIcon
      disableInitialCountryGuess={false}
    />
  </div>
);

export default PhoneInputWrapper;
