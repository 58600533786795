import React from 'react';

const SendOtpButton = ({ step, isPhoneOrEmailEmpty, isOtpEmpty, sendOtp, verifyOtp, t }) => (
  <div className="send-otp-btn-wrapper">
    <button
      className={`action-btn ${step === 1 ? (isPhoneOrEmailEmpty ? 'disabled' : '') : (isOtpEmpty ? 'disabled' : '')}`}
      onClick={step === 1 ? sendOtp : verifyOtp}
      disabled={step === 1 ? isPhoneOrEmailEmpty : isOtpEmpty}
    >
      {step === 1 ? t("login.otp.sendcode") : t("login.otp.submit")}
    </button>
  </div>
);

export default SendOtpButton;
