import React from 'react';

export const GoogleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.588" viewBox="0 0 24 24.588">
      <defs>
        <clipPath id="v89duy4xya">
          <path style={{fill: 'none'}} d="M0 0h24v24.588H0z"/>
        </clipPath>
      </defs>
      <g style={{clipPath: 'url(#v89duy4xya)'}}>
        <path
          d="M11.32 6.652a12.184 12.184 0 0 1 5.58-5.409 11.755 11.755 0 0 1 9.617-.4 11.288 11.288 0 0 1 3.648 2.231c.2.186.134.224.021.338-1.037 1.042-2.079 2.08-3.114 3.125-.147.148-.255.14-.4.007C23.22 3.467 17.226 4.82 15.384 9.84a.552.552 0 0 1-.064.1c-1.32-.912-2.539-1.952-3.806-2.933a.432.432 0 0 1-.194-.355"
          transform="translate(-9.951)"
          style={{fill: '#e02b27'}}
        />
        <path
          d="M14.9 121.053a7.454 7.454 0 0 0 3.74 4.3 7.345 7.345 0 0 0 7.252-.37l3.969 3.073a10.427 10.427 0 0 1-6.42 2.865 12.1 12.1 0 0 1-12.209-5.984c-.15-.236-.262-.5-.392-.746l4.06-3.136"
          transform="translate(-9.531 -106.412)"
          style={{fill: '#3c983f'}}
        />
        <path
          d="m109.459 94.51-3.969-3.073a6.063 6.063 0 0 0 2.326-3.126 1.756 1.756 0 0 0 .106-.707c-.033-.063-.071-.081-.118-.013h-6.292l-.122-.108v-4.469l.125-.121h11.241l.113.117c.786 3.545-.193 8.758-3.411 11.5"
          transform="translate(-89.127 -72.868)"
          style={{fill: '#3169b1'}}
        />
        <path
          d="m5.371 62.99-4.06 3.136a12.49 12.49 0 0 1-.145-10.843 1.661 1.661 0 0 1 .2-.282.431.431 0 0 0 .194.355c1.267.981 2.487 2.021 3.806 2.935a7.3 7.3 0 0 0 0 4.7"
          transform="translate(0 -48.349)"
          style={{fill: '#feab00'}}
        />
        <path
          d="m101.515 82.89-.125.121c0-.088.04-.125.125-.121"
          transform="translate(-89.126 -72.864)"
          style={{fill: '#205faa'}}
        />
        <path
          d="m195.476 83.007-.113-.117c.085-.006.116.039.113.117"
          transform="translate(-171.734 -72.864)"
          style={{fill: '#205faa'}}
        />
        <path
          d="m101.387 120.841.122.108c-.076 0-.128-.019-.122-.108"
          transform="translate(-89.124 -106.225)"
          style={{fill: '#205faa'}}
        />
        <path
          d="M154.418 121.417c.047-.068.085-.05.118.013a.274.274 0 0 1-.118-.013"
          transform="translate(-135.741 -106.693)"
          style={{fill: '#205faa'}}
        />
      </g>
    </svg>
  );
};

export const AppleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.449"
      height="24.273"
      viewBox="0 0 20.449 24.273">
      <defs>
        <clipPath id="t9mxr6c39a">
          <path style={{fill: 'none'}} d="M0 0h20.449v24.273H0z"/>
        </clipPath>
      </defs>
      <g style={{clipPath: 'url(#t9mxr6c39a)'}}>
        <path
          d="M19.794 143.925c-.2.136-.387.26-.57.394a5.29 5.29 0 0 0-1.526 1.7 4.882 4.882 0 0 0-.571 1.819 5.937 5.937 0 0 0 .009 1.443 5.027 5.027 0 0 0 1.438 2.962 5.679 5.679 0 0 0 1.809 1.263c.02.009.039.021.066.036l-.112.318a14.019 14.019 0 0 1-2.275 4.043 8.315 8.315 0 0 1-1.185 1.279 3.459 3.459 0 0 1-1.321.714 2.8 2.8 0 0 1-1.1.071 6.832 6.832 0 0 1-1.669-.485 6.091 6.091 0 0 0-1.466-.4 4.588 4.588 0 0 0-1.05-.016 6.572 6.572 0 0 0-1.835.484 8.736 8.736 0 0 1-1.219.388 2.687 2.687 0 0 1-2.25-.443 6 6 0 0 1-1.121-1.015 15.842 15.842 0 0 1-2.209-3.214 15.67 15.67 0 0 1-1.4-4.039A12.06 12.06 0 0 1 0 148.594a8.3 8.3 0 0 1 .643-3.152 6.551 6.551 0 0 1 1.627-2.308 6.037 6.037 0 0 1 2.93-1.469 6.672 6.672 0 0 1 .918-.106 4.1 4.1 0 0 1 1.111.123 12.617 12.617 0 0 1 1.6.509c.393.145.79.279 1.2.386a1.266 1.266 0 0 0 .749-.018c.525-.178 1.051-.348 1.574-.531a10.555 10.555 0 0 1 1.531-.433 5.163 5.163 0 0 1 1.5-.069 7.468 7.468 0 0 1 1.733.376 5.41 5.41 0 0 1 2.626 1.945l.055.08"
          transform="translate(0 -135.748)"
          style={{fill: '#161615'}}
        />
        <path
          d="M251.548 5.735c-.006-.061-.013-.111-.017-.161a4.49 4.49 0 0 1 .4-2.184A5.669 5.669 0 0 1 255.92.1a3.442 3.442 0 0 1 .687-.1c.005.043.012.083.015.123a4.872 4.872 0 0 1-.788 3.046 6.294 6.294 0 0 1-1.9 1.934 3.993 3.993 0 0 1-1.5.6 3.362 3.362 0 0 1-.737.05c-.046 0-.091-.01-.149-.017"
          transform="translate(-241.298)"
          style={{fill: '#161615'}}
        />
      </g>
    </svg>
  );
};

export const FacebookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.008"
      height="24.008"
      viewBox="0 0 24.008 24.008">
      <path
        d="M174.008 82a12 12 0 1 0-13.879 11.859v-8.384h-3.049V82h3.049v-2.641c0-3.008 1.793-4.671 4.534-4.671a18.454 18.454 0 0 1 2.687.235v2.954h-1.514a1.736 1.736 0 0 0-1.956 1.875V82h3.329l-.532 3.471h-2.8v8.388A12.006 12.006 0 0 0 174.008 82z"
        transform="translate(-150 -70)"
        style={{fill: '#3c5997', fillRule: 'evenodd'}}
      />
      <path
        d="m236.3 131.573.532-3.471H233.5v-2.252a1.735 1.735 0 0 1 1.956-1.875h1.514v-2.954a18.453 18.453 0 0 0-2.687-.235c-2.741 0-4.534 1.661-4.534 4.671v2.643H226.7v3.471h3.049v8.388a12.063 12.063 0 0 0 1.875.145 12.274 12.274 0 0 0 1.875-.145v-8.388h2.8z"
        transform="translate(-219.622 -116.098)"
        style={{fill: '#fefefe', fillRule: 'evenodd'}}
      />
    </svg>
  );
};

export const SsoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27.013" viewBox="0 0 27 27.013">
      <defs>
        <clipPath id="d0kv1jsa3a">
          <path d="M0 0h27v27.013H0z" style={{fill: '#c37cff'}}/>
        </clipPath>
      </defs>
      <g>
        <g style={{clipPath: 'url(#d0kv1jsa3a)'}}>
          <path
            d="M85.206 73.129h-3.072V57.864l-.6-.067V55.38c.166-.007.314-.02.461-.02 3.352 0 6.7 0 10.056-.008.368 0 .51.081.49.475-.034.645-.009 1.293-.009 1.966l-.59.06v15.252h-3.088v-4.21h-3.648zm.546-12.821h-1.77V62.1h1.77zm4.294.008h-1.76V62.1h1.759zm-4.29 4.284h-1.763v1.79h1.763zm2.522 1.776h1.774v-1.79h-1.774z"
            transform="translate(-73.805 -50.733)"
            style={{fill: '#c37cff'}}
          />
          <path
            d="M43.72 103.574h-6.078v-8.54l-.621-.06v-2.413h6.7z"
            transform="translate(-36.662 -81.779)"
            style={{fill: '#c37cff'}}
          />
          <path
            d="M157.948 103.567h-6.1V92.552h6.686v2.412l-.587.055z"
            transform="translate(-132.479 -81.772)"
            style={{fill: '#c37cff'}}
          />
        </g>
      </g>
    </svg>
  );
};

export const OTPIcon = () => {
  let fillNone = {
    fill: 'none',
  }
  let fillGray = {
    fill: '#161615'
  }
  let style = {
    stroke: '#161615', strokeMiterlimit: '10', strokeWidth: '.25px', fill: 'none'
  }
  let fillWhite = {
    fill: '#fff'
  }
  return (
    <svg width="32.17" height="20.084" viewBox="0 0 32.17 20.084">
      <defs>
        <clipPath id="3tg85lupwa">
          <path style={fillNone} d="M0 0h16.324v11.025H0z"/>
        </clipPath>
        <clipPath id="99le42oqob">
          <path style={fillNone} d="M0 0h11.638v20.084H0z"/>
        </clipPath>
      </defs>
      <g>
        <g>
          <g transform="translate(15.845 4.986)" style={{
            clipPath: 'url(#3tg85lupwa)'
          }}>
            <path
              d="M8.932 1.007h6.548a1.309 1.309 0 0 1 1.334.974 1.3 1.3 0 0 1 .043.366v7.878a1.3 1.3 0 0 1-.982 1.281 2.212 2.212 0 0 1-.441.053H2.429a1.31 1.31 0 0 1-1.379-.986 1.552 1.552 0 0 1-.045-.44c0-2.57.009-5.14-.008-7.71A1.383 1.383 0 0 1 2.438 1c2.164.019 4.329.006 6.493.006M1.685 2.138a2.347 2.347 0 0 0-.028.239v7.8a.663.663 0 0 0 .723.715h13.1a.665.665 0 0 0 .73-.726V2.382a1.885 1.885 0 0 0-.031-.235l-.165.139-6.09 5.467a1.346 1.346 0 0 1-1.978 0Q5.627 5.68 3.311 3.6L1.685 2.138m14-.454H2.223l-.028.038c.048.036.1.067.144.107l6.077 5.452a.664.664 0 0 0 1.032 0l5.733-5.141c.159-.143.317-.287.5-.456"
              transform="translate(-.768 -.769)" style={fillGray}/>
            <path
              d="M6.349 11.026H1.664a1.549 1.549 0 0 1-1.6-1.152 1.754 1.754 0 0 1-.054-.506V6.819c0-1.691 0-3.44-.008-5.16A1.639 1.639 0 0 1 .47.474 1.667 1.667 0 0 1 1.672 0C3.22.014 4.794.011 6.317.009h8.395a1.542 1.542 0 0 1 1.609 1.572v7.877a1.535 1.535 0 0 1-1.167 1.509 2.393 2.393 0 0 1-.488.057h-8.32M1.655.464A1.2 1.2 0 0 0 .8.8a1.178 1.178 0 0 0-.334.852c.011 1.721.01 3.471.008 5.163v2.549a1.355 1.355 0 0 0 .035.373 1.079 1.079 0 0 0 1.157.821h13a1.941 1.941 0 0 0 .394-.048 1.063 1.063 0 0 0 .8-1.054V1.578a1.1 1.1 0 0 0-.033-.3 1.137 1.137 0 0 0-.35-.557l-.587.533c-.111.1-.215.2-.319.289L8.836 6.686a.893.893 0 0 1-1.342 0L1.416 1.234a.753.753 0 0 0-.076-.056L1.1 1l.238-.32h14.086a1.177 1.177 0 0 0-.712-.213H6.318c-1.524 0-3.1.006-4.65-.009h-.013m.36.684L7.8 6.34a.444.444 0 0 0 .722 0l5.736-5.14.057-.051zm6.146 9.211H1.613a.892.892 0 0 1-.955-.946v-7.8a1.228 1.228 0 0 1 .017-.172c0-.027.009-.058.013-.094L.74.9l3.371 3.025 3.223 2.892A1.121 1.121 0 0 0 9 6.812q2.053-1.841 4.1-3.684l1.983-1.78c.032-.029.065-.056.107-.09l.386-.322.058.415c0 .03.009.057.013.082a1.074 1.074 0 0 1 .02.185v7.785a.891.891 0 0 1-.962.957H8.162M1.121 1.865v7.547a.436.436 0 0 0 .491.483h13.1a.436.436 0 0 0 .5-.494V1.864l-1.796 1.609-4.105 3.685a1.583 1.583 0 0 1-2.288.005Q5.411 5.718 3.8 4.27z"
              style={fillGray}/>
          </g>
        </g>
        <g>
          <g>
            <g style={{
              clipPath: 'url(#99le42oqob)'
            }}>
              <path
                d="M11.469 10.083v8.671c0 .823-.339 1.162-1.162 1.162H1.382c-.786 0-1.134-.35-1.134-1.139V1.384C.248.6.6.249 1.385.249h8.95c.784 0 1.133.351 1.133 1.138v8.7M10.787 2.6H.942v13.338h9.845V2.6M.956 16.625a.458.458 0 0 0-.028.114v2.2c0 .256.161.31.379.31h9.126c.285 0 .366-.082.368-.37V16.63H.956m9.835-14.724V1.38c0-.415-.047-.463-.457-.463H1.392c-.077 0-.172-.024-.229.011-.093.056-.219.146-.23.234a5.9 5.9 0 0 0-.011.743h9.869"
                transform="translate(-.039 -.04)" style={fillGray}/>
              <path
                d="M11.469 10.083v8.671c0 .823-.339 1.162-1.162 1.162H1.382c-.786 0-1.134-.35-1.134-1.139V1.384C.248.6.6.249 1.385.249h8.95c.784 0 1.133.351 1.133 1.138l.001 8.696zM10.787 2.6H.942v13.338h9.845zM.956 16.625a.458.458 0 0 0-.028.114v2.2c0 .256.161.31.379.31h9.126c.285 0 .366-.082.368-.37V16.63zm9.835-14.719V1.38c0-.415-.047-.463-.457-.463H1.392c-.077 0-.172-.024-.229.011-.093.056-.219.146-.23.234a5.9 5.9 0 0 0-.011.743z"
                transform="translate(-.039 -.04)"
                style={style}/>
              <path
                d="M9.012 20H1.363a1.081 1.081 0 0 1-1.237-1.244V1.365A1.08 1.08 0 0 1 1.366.126h8.95a1.081 1.081 0 0 1 1.237 1.242v17.366c0 .875-.39 1.265-1.266 1.265zM5.841.333H1.366C.642.333.333.642.333 1.365v17.391c0 .735.3 1.035 1.03 1.035h8.924c.762 0 1.059-.3 1.059-1.058V1.368c0-.735-.3-1.035-1.03-1.035H5.841m-4.56 19c-.124 0-.477-.005-.477-.413v-2.2a.346.346 0 0 1 .023-.112l.008-.027.02-.078h10.03v2.354c0 .356-.13.474-.471.474H1.281zm-.267-2.623v2.21c0 .14.041.206.273.206H10.413c.228 0 .262-.034.264-.267V16.71zm9.857-.688H.819V3.075h10.052zm-9.845-.207h9.638V3.3H1.027zm9.85-13.824H.8v-.31a3.709 3.709 0 0 1 .011-.551c.013-.1.1-.2.279-.309a.36.36 0 0 1 .222-.03h9.003c.466 0 .56.1.561.566zm-9.87-.208h9.662v-.422c0-.358 0-.358-.353-.358H1.372C1.346 1 1.321 1 1.3 1a.307.307 0 0 0-.1 0c-.172.105-.181.157-.181.158A3.339 3.339 0 0 0 1 1.671v.112"
                transform="translate(-.02 -.02)" style={fillGray}/>
              <path
                d="M9.012 20H1.363a1.081 1.081 0 0 1-1.237-1.244V1.365A1.08 1.08 0 0 1 1.366.126h8.95a1.081 1.081 0 0 1 1.237 1.242v17.366c0 .875-.39 1.265-1.266 1.265zM5.841.333H1.366C.642.333.333.642.333 1.365v17.391c0 .735.3 1.035 1.03 1.035h8.924c.762 0 1.059-.3 1.059-1.058V1.368c0-.735-.3-1.035-1.03-1.035zm-4.56 19c-.124 0-.477-.005-.477-.413v-2.2a.346.346 0 0 1 .023-.112l.008-.027.02-.078h10.03v2.354c0 .356-.13.474-.471.474H1.281zm-.267-2.623v2.21c0 .14.041.206.273.206H10.413c.228 0 .262-.034.264-.267V16.71zm9.857-.688H.819V3.075h10.052zm-9.845-.207h9.638V3.3H1.027zm9.85-13.824H.8v-.31a3.709 3.709 0 0 1 .011-.551c.013-.1.1-.2.279-.309a.36.36 0 0 1 .222-.03h9.003c.466 0 .56.1.561.566zm-9.87-.208h9.662v-.422c0-.358 0-.358-.353-.358H1.372C1.346 1 1.321 1 1.3 1a.307.307 0 0 0-.1 0c-.172.105-.181.157-.181.158A3.339 3.339 0 0 0 1 1.671z"
                transform="translate(-.02 -.02)"
                style={style}/>
              <path
                d="M1.084 19.718h9.843v2.249c0 .289-.082.371-.368.371H1.433c-.218 0-.379-.054-.379-.31v-2.2a.5.5 0 0 1 .029-.114"
                transform="translate(-.167 -3.132)" style={fillWhite}/>
              <path
                d="M1.084 19.718h9.843v2.249c0 .289-.082.371-.368.371H1.433c-.218 0-.379-.054-.379-.31v-2.2a.5.5 0 0 1 .03-.11z"
                transform="translate(-.167 -3.132)"
                style={style}/>
              <path
                d="M1.393 22.508c-.484 0-.585-.281-.585-.517v-2.2a.449.449 0 0 1 .028-.145l.007-.02.04-.156H11.1v2.458c0 .4-.174.577-.575.577H1.394zm-.169-2.622v2.1c0 .082 0 .1.17.1H10.52a.33.33 0 0 0 .146-.018.433.433 0 0 0 .016-.147v-2.04z"
                transform="translate(-.128 -3.093)" style={fillGray}/>
              <path
                d="M1.393 22.508c-.484 0-.585-.281-.585-.517v-2.2a.449.449 0 0 1 .028-.145l.007-.02.04-.156H11.1v2.458c0 .4-.174.577-.575.577H1.394zm-.169-2.622v2.1c0 .082 0 .1.17.1H10.52a.33.33 0 0 0 .146-.018.433.433 0 0 0 .016-.147v-2.04z"
                transform="translate(-.128 -3.093)"
                style={style}/>
              <path
                d="M10.917 3.444H1.048c0-.58-.02-1.16.011-1.724a1.455 1.455 0 0 1 .076-.62c.057-.082 6.344.051 9.325.052.41 0 .456.111.457 1.072v1.22"
                transform="translate(-.165 -.162)" style={fillWhite}/>
              <path
                d="M10.917 3.466H1.048c0-.58-.02-1.16.011-1.724a1.128 1.128 0 0 1 .23-.541c.057-.082 6.19-.028 9.171-.027.41 0 .456.111.457 1.072z"
                transform="translate(-.165 -.184)"
                style={style}/>
              <path
                d="M11.086 4.177H.8v-1c0-.439-.008-.892.016-1.348a1.74 1.74 0 0 1 .326-.921A.237.237 0 0 1 1.427.8c.018 0 .031 0 .052.008h8.942c.527 0 .664.331.665 1.608zm-9.872-1h9.457v-1.04c0-.5-.009-.7-.017-.784-.024-.026-.086-.052-.233-.052-2.444 0-9.205-.135-9.208-.135-.075.156.021.366.01.408-.018.5-.013 1.022-.01 1.573v.033"
                transform="translate(-.126 -.125)" style={fillGray}/>
              <path
                d="M11.086 4.177H.8v-1c0-.439-.008-.892.016-1.348a1.74 1.74 0 0 1 .326-.921A.237.237 0 0 1 1.427.8c.018 0 .031 0 .052.008h8.942c.527 0 .664.331.665 1.608zm-9.872-1h9.457v-1.04c0-.5-.009-.7-.017-.784-.024-.026-.086-.052-.233-.052-2.611 0 .263.019-9.2 0 0 0 .006.337-.005.379-.018.5 0 .915 0 1.466zm0-1.16z"
                transform="translate(-.126 -.125)"
                style={style}/>
              <path d="M7.4 21.165a.59.59 0 1 1-.59-.59.59.59 0 0 1 .59.59"
                    transform="translate(-.989 -3.269)" style={fillGray}/>
            </g>
          </g>
          <path transform="translate(.74 .464)" style={fillGray} d="M0 0h2.961v.74H0z"/>
        </g>
      </g>
    </svg>
  );
};
