import { EVENTS } from './mixPanelEvents';

const isProduction = global.globalConfigTools.isProduction();

class MixpanelService {
  track(event, properties = {}) {
    if (window.mixpanel && window.mixpanel.track) {
      const defaultProperties = {
        Type: 'Login App',
        ...properties
      };

      if (isProduction) {
        window.mixpanel.track(event, defaultProperties);
      } else {
        // Log events to the console in development mode
        console.log(`Development - Event: ${event}, Properties: `, defaultProperties);
        window.mixpanel.track(event, defaultProperties);
      }
    } else {
      console.error('Mixpanel tracking failed. Mixpanel is not initialized.');
    }
  }

  trackLoginSuccess(method) {
    this.track(EVENTS.LOGIN_SUCCESS, { method });
  }

  trackLoginFailure(method, reason) {
    this.track(EVENTS.LOGIN_FAIL, { method, reason });
  }

  trackPasswordToggle(showPassword) {
    this.track(EVENTS.SHOW_PASSWORD, { showPassword });
  }

  trackForgotPassword() {
    this.track(EVENTS.FORGOT_PASSWORD);
  }

  trackGoogleSignInSuccess() {
    this.track(EVENTS.GOOGLE_SIGNIN_SUCCESS);
  }

  trackGoogleSignInFail(reason) {
    this.track(EVENTS.GOOGLE_SIGNIN_FAIL, { reason });
  }

  trackSSOSuccess(provider) {
    this.track(EVENTS.SSO_SUCCESS,{ provider });
  }

  trackSSOFail(reason) {
    this.track(EVENTS.SSO_FAIL, { reason });
  }

  trackSwitchToggle(toggleState) {
    this.track(EVENTS.SWITCH_TOGGLE, { toggleState });
  }

  trackOTPSuccess() {
    this.track(EVENTS.OTP_SUCCESS);
  }

  trackOTPFailure(reason) {
    this.track(EVENTS.OTP_FAIL, { reason });
  }

  trackOTLSuccess() {
    this.track(EVENTS.OTL_SUCCESS);
  }

  trackOTLFailure(reason) {
    this.track(EVENTS.OTL_FAIL, { reason });
  }

  trackResendCode(method) {
    this.track(EVENTS.RESEND_CODE,{ method });
  }

  trackUserOnHold(reason) {
    this.track(EVENTS.USER_ON_HOLD,{reason});
  }

  trackChangeLanguage(language) {
    this.track(EVENTS.CHANGE_LANGUAGE, { language });
  }
}

export default new MixpanelService();
