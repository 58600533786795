import React from "react";
import "../../assets/stylesheets/password-modal.css";
import {ResetModalIcon} from "../../assets/svg/password-modal-icons";
import {translate} from "react-translate";
import LanguageSelector from "../onBoarding/components/language-selector";
import {
    ClosedEyeIcon, OpenEyeIcon,
} from "../../assets/svg/password-modal-icons";
import ApiService from "../../services/ApiService";
import {auth} from "../../services/firebase";
import CloseButton from "../common/close-button";
import { redirect } from "../../services/utils";
import { REDIRECT_TYPES } from "../../assets/appConstants";
import mixPanelService from "../../services/mixPanel/mixPanelService";



class ResetPasswordModal extends React.Component {
    constructor(props) {
        super(props);

      this.state = {
            language: props.language, errorMsg: "", showPassword: false, inputType: "password",
        };

        this.validatePassword = this.validatePassword.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.language !== this.props.language) {
            this.setState({
                language: this.props.language,
            });
        }


    }

    switchLanguage(lang) {
        this.setState({
            language: lang,
        });
        this.props.setTranslation(lang);
    }

    redirectToHomepage() {
      redirect(REDIRECT_TYPES.HOME)
    }

    togglePassword() {
        const {showPassword} = this.state;
        let _show = !showPassword;
        let _inputType = _show ? "text" : "password";
        this.setState({
            inputType: _inputType, showPassword: _show,
        });
        mixPanelService.trackPasswordToggle(!showPassword);
      let password = document.querySelector("#resetInput");
      password.focus();
    }

    validatePassword() {
        const {t} = this.props;
        const inputValue = document.getElementById("resetInput").value;
        let errorMsg = "";
        let isValid = true;
        if (!inputValue) {
            isValid = false;
            errorMsg = t("reset.password.error");
        } else if (inputValue.length < 8) {
            isValid = false;
            errorMsg = t("reset.password.invalid");
        }
        //if all is valid - send request
        if (isValid) this.updatePassword(inputValue)
                this.setState({
            errorMsg,
        });
    }

    updatePassword(value) {
        const accountId = auth.currentUser.uid
        let accountData = {
            password: value,
        };
        ApiService.updateAccountData(accountId, accountData).then((response) => {

            if (response) {
                this.props.handleClose();
                this.props.handleConfirmModal();
            }
        });
    }

    render() {
        const {t, open} = this.props;
        const {language, errorMsg, showPassword, inputType} = this.state;
        return (open && (<div className={"modal-wrapper"}>
            <div className="modal-content">
              <CloseButton/>
                <LanguageSelector
                    isModal={true}
                    language={language}
                    switchLang={this.switchLanguage.bind(this)}
                />
                <div className="icon">
                    <ResetModalIcon/>
                </div>
                <div className="title-wrapper">
                    <div className="title">{t("reset.title")}</div>
                    <div className="sub-title">{t("reset.subtitle")}</div>
                </div>
                <div className="input-wrapper">
                    <div className="input-label">{t("reset.hint")}</div>
                    <input
                        type={inputType}
                        className={"input"}
                        id={"resetInput"}
                        placeholder={t("reset.placeholder")}
                    />
                    <div
                        className="eye-icon"
                        onClick={this.togglePassword.bind(this)}
                    >
                        {showPassword ? <OpenEyeIcon/> : <ClosedEyeIcon/>}
                    </div>
                    <div className="error-msg">{errorMsg}</div>
                </div>
                <div className="btns-wrapper">
                    <div className="send-btn" onClick={this.validatePassword}>
                        {t("reset.btn")}
                    </div>
                    <div className="cancel-btn" onClick={this.redirectToHomepage}>
                        {t("reset.alt.btn")}
                    </div>
                </div>
            </div>
        </div>));
    }
}

export default translate("reset")(ResetPasswordModal);
