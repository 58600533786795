import React from "react";
import {translate} from "react-translate";
import DateSelector from "../components/date-selector";
import GenderSelector from "../components/gender-selector";
import OnBoardService from "../onboard-service";
import {
  RedQuestionMark,
  WhiteIIcon,
  IntroductionCloseX,
} from "../../../assets/svg/onboard-icons";

class OnBoardStep3 extends React.Component {
  fields = {
    dob: "dob",
    gender: "gender",
    acceptTerms: "acceptTerms",
  };

  constructor(props) {
    super(props);
    this.state = {
      termsModalOpen: false,
    };
    this.onStepValueChange = this.onStepValueChange.bind(this);
    this.toggleTermsModal = this.toggleTermsModal.bind(this);
  }

  onStepValueChange(e, fieldName) {
    let {stepData} = this.props;
    let _newData = stepData.stepData;

    switch (fieldName) {
      case this.fields.acceptTerms:
        _newData.acceptTerms.value = e.currentTarget.checked;
        break;
      case this.fields.dob:
        _newData.dob.value = e.currentTarget.value;
        break;
      case this.fields.gender:
        _newData.gender.value = e.currentTarget.value;
        break;
      default:
        break;
    }

    stepData.setStepData(_newData);
    OnBoardService.notify();
  }

  toggleTermsModal(isShow) {
    this.setState({
      termsModalOpen: isShow,
    });
  }

  render() {
    const {t, stepData, showValidation} = this.props;
    const {termsModalOpen} = this.state;
    return (
      <div className="item-row onboard-step">
        {termsModalOpen && (
          <div className="terms-modal">
            <div
              className="close-modal-icon"
              onClick={() => {
                this.toggleTermsModal(false);
              }}
            >
              <IntroductionCloseX/>
            </div>
            <iframe src={t('wizard.terms.link')} style={{
              width: '100%',
              height: '100%',
              border: 'none',
            }}/>
            {/*<div dangerouslySetInnerHTML={{ __html: t("wizard.terms") }} />*/}
          </div>
        )}
        <div className="item-col-wrap col-100 flex-direction-col">
          <div className="step-caption">{t("wizard.step.tree.title")}</div>
          <div className="step-form">
            <div className="control-wrap">
              <div className="control-title"> {t("wizard.step.tree.dob")}</div>
              <div className="control">
                <DateSelector
                  date={stepData.stepData.dob.value}
                  onStepValueChange={this.onStepValueChange}
                />
                {!stepData.stepData.dob.isValid && showValidation && (
                  <>
                    <div className="red-q-mark dob">
                      <RedQuestionMark/>
                    </div>
                    <div className="error-msg dob">
                      {t("wizard.step.tree.dob.error")}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="control-wrap">
              <div className="control-title">
                {t("wizard.step.tree.gender")}
              </div>
              <div className="control">
                <GenderSelector
                  gender={stepData.stepData.gender.value}
                  onStepValueChange={this.onStepValueChange}
                />
              </div>
            </div>
            <div className="control-wrap">
              <div className="control">
                <input
                  type="checkbox"
                  id="acceptTerms"
                  onChange={(e) => {
                    this.onStepValueChange(e, this.fields.acceptTerms);
                  }}
                  checked={stepData.stepData.acceptTerms.value}
                />
                <label
                  htmlFor="acceptTerms"
                  className="control-label"
                >
                  {t("wizard.step.tree.agree")}
                </label>
                <div
                  className="cb-icon"
                  onClick={() => {
                    this.toggleTermsModal(true);
                  }}
                >
                  <WhiteIIcon/>
                </div>
                {!stepData.stepData.acceptTerms.isValid && showValidation && (
                  <>
                    <div className="red-q-mark agree">
                      <RedQuestionMark/>
                    </div>
                    <div className="error-msg agree">
                      {t("wizard.step.tree.agree.error")}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate("onboarding")(OnBoardStep3);
