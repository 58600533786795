import React from "react";
import {translate} from "react-translate";
import OnBoardService from "../onboard-service";
import OnBoardSmsSender from "../components/onboard-sms";
import OnBoardDoneStep from "../components/onboard-done-step";
import ApiService from "../../../services/ApiService";
import { MESSAGES_TYPES, MOBILE_HOSTS} from "../../../assets/appConstants";
import {postMessage} from "../../../services/utils";

class OnBoardStep4 extends React.Component {
  constructor(props) {
    super(props);

    //if it is mobile but only app embeded (not web). don't show this page
    let host = ApiService.getAppHost()
    let isMobile = Object.values(MOBILE_HOSTS).indexOf(host) > -1 && this.props.isMobile;
    if (isMobile || true) {//for now don't show this page at all
      postMessage(MESSAGES_TYPES.LOGIN_SUCCESS, {immediateRedirect: true})
    }
  }
  renderStep() {
    const {stepData, closeHandler, accountId, isMobile, loginData, language} =
      this.props;
    const email = stepData.stepData.email.value;
    const phone = stepData.stepData.phone.value;
    const hasPhone = phone && phone.lineNumber;
    let elem;
    let isSms = false;



    if ((email && hasPhone) || (!email && hasPhone)) {
      elem = (
        <OnBoardDoneStep
          hasEmail={!!email}
          stepData={stepData}
          closeHandler={closeHandler}
          isMobile={isMobile}
          loginData={loginData}
        />
      );
    } else if (email && phone.length === 0) {
      const countryCode = OnBoardService.getCountryCode();
      isSms = true;
      elem = (
        <OnBoardSmsSender
          stepData={stepData}
          accountId={accountId}
          countryCode={countryCode}
          language={language}
          isMobile={isMobile}
          loginData={loginData}
        />
      );
    }
    return {elem, isSms};
  }

  render() {
    const {elem, isSms} = this.renderStep();
    return <div className="item-row onboard-step" style={isSms ? {paddingTop: 0} : {}}> {elem}</div>;
  }
}

export default translate("onboarding")(OnBoardStep4);
