import React from 'react';

const MethodSelection = ({ method, onMethodChange, t }) => (
  <div className="method-selection">
    <div className="radio-btn">
      <input
        type="radio"
        value="phone"
        checked={method === 'phone'}
        onChange={onMethodChange}
        id="phone"
      />
      <label htmlFor="phone">
        <span>{t("login.method.txt.phone")}</span>
      </label>
    </div>
    <div className="radio-btn">
      <input
        type="radio"
        value="email"
        checked={method === 'email'}
        onChange={onMethodChange}
        id="email"
      />
      <label htmlFor="email">
        <span>{t("login.method.txt.email")}</span>
      </label>
    </div>
  </div>
);

export default MethodSelection;
