import {EventEmitter} from "events";
import assign from "object-assign";
import {MESSAGES_TYPES} from "../assets/appConstants";
import {postMessage} from "./utils";

const CHANGE_EVENT = "change";
let _alert = {};
let _error = null;
let _showAlert = false;
let _showSpinner = false;

const SystemPromptService = assign({}, EventEmitter.prototype, {
  spinnerGifPath: 'https://storage.googleapis.com/wize-storage/public_assets/loading-screen_no_margin.gif',
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  },
  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },
  emitChange() {
    this.emit(CHANGE_EVENT);
  },
  showAlert(alert, errorCaught = null) {
    postMessage(MESSAGES_TYPES.HIDE_PRELOADER) //hide host preloader so the alert can be seen
    _showAlert = true;
    _showSpinner = false;
    _alert = alert;
    _error = errorCaught
    this.emitChange();
  },
  hideAlert() {
    _showAlert = false;
    _alert = {};
    this.emitChange();
  },
  getAlert() {
    return _alert;
  },
  getError() {
    return _error;
  },
  isAlertToShow() {
    return _showAlert;
  },
  showSpinner() {
    _showSpinner = true;
    this.emitChange();
  },
  hideSpinner() {
    _showSpinner = false;
    _alert = {};
    setTimeout(this.emitChange.bind(this),500)
    ;
  },
  isSpinnerToShow() {
    return _showSpinner;
  },
});

export default SystemPromptService;
