import React from "react";
import "../../assets/stylesheets/password-modal.css";
import {ForgotModalIcon} from "../../assets/svg/password-modal-icons";
import {translate} from "react-translate";
import LanguageSelector from "../onBoarding/components/language-selector";
import {RedQuestionMark} from "../../assets/svg/onboard-icons";
import ApiService, {
  convertPhoneToMail,
  validateEmail,
  validatePhone,
} from "../../services/ApiService";
import CloseButton from "../common/close-button";
import {postMessage} from "../../services/utils";
import {MESSAGES_TYPES} from "../../assets/appConstants";
import mixPanelService from "../../services/mixPanel/mixPanelService";


class ForgotPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: props.language,
      errorMsg: "",
    };
    this.sendLink = this.sendLink.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.language !== this.props.language) {
      this.setState({
        language: this.props.language,
      });
    }
  }

  switchLanguage(lang) {
    this.setState({
      language: lang,
    });
    this.props.setTranslation(lang);
  }

  onInputFocus(e) {
    e.target.classList.remove('invalid');
    this.setState({
      errorMsg: ''
    })
  }

  validateInput() {
    const {t} = this.props;
    //validate phone or email
    const inputValue = document.getElementById("forgotInput").value;
    let isValid = true;
    let isPhone = true;
    let errorMsg = "";
    //first validation - check for value
    if (!inputValue) {
      errorMsg = t("forgot.input.error");
      isValid = false;
    }
    //second validation - check if it is phone or email
    else if (isNaN(parseInt(inputValue))) {
      isPhone = false;
      //third validation - check if mail is valid
      if (!validateEmail(inputValue)) {
        isValid = false;
        errorMsg = t("forgot.email.invalid");
      }
    }
    //fourth validation - check if phone is valid
    else if (!validatePhone(inputValue)) {
      isValid = false;
      errorMsg = t("forgot.phone.invalid");
    }

    //if all valid - send request
    if (isValid){
      mixPanelService.trackForgotPassword();
      this.sendLink(inputValue, isPhone);
    } 

    //if not valid - show proper message
    this.setState({
      errorMsg,
    });
  }

  sendLink(value, isPhone) {
    let parsedValue = isPhone ? convertPhoneToMail(value) : value;
    ApiService.sendResetLink(parsedValue).then((res) => {
      if (res) {
        //all good - show confirmation modal
        this.props.toggleConfirmModal();
        this.props.handleClose();
      }
    });
  }

  render() {
    const {open, t} = this.props;
    const {language, errorMsg} = this.state;
    return (
      open && (
        <div className={"modal-wrapper"}>
          <div className="modal-content">
            <CloseButton/>
            <LanguageSelector
              isModal={true}
              language={language}
              switchLang={this.switchLanguage.bind(this)}
            />
            <div className="icon">
              <ForgotModalIcon/>
            </div>
            <div className="title-wrapper">
              <div className="title">{t("forgot.title")}</div>
              <div className="sub-title">{t("forgot.subtitle")}</div>
            </div>
            <div className="input-wrapper">
              <div className="input-label">{t("forgot.hint")}</div>
              <div className="inner-wrapper">
                <input
                  type="text"
                  className={`input ${errorMsg ? 'invalid' : ''}`}
                  id={"forgotInput"}
                  placeholder={t("forgot.placeholder")}
                  onFocus={this.onInputFocus}
                />
                {errorMsg && <div className="error-icon">
                  <RedQuestionMark/>
                </div>}
              </div>
              <div
                style={{
                  visibility: errorMsg ? "visible" : "hidden",
                }}
              >
                <div className="error-msg">{errorMsg}</div>
              </div>
            </div>
            <div className="btns-wrapper">
              <div className="send-btn" onClick={this.validateInput}>
                {t("forgot.btn")}
              </div>
              <div
                className="cancel-btn"
                onClick={() => {
                  this.props.handleClose();
                  this.props.toggleLoginModal();
                  postMessage(MESSAGES_TYPES.BACK_HOME)
                }}
              >
                {t("forgot.alt.btn")}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default translate("forgot")(ForgotPasswordModal);
