import React from "react";
import { translate } from "react-translate";
import OnBoardService from "../onboard-service";
import "../../../assets/stylesheets/gender-selector.css";

class GenderSelector extends React.Component {
  constructor(props) {
    super(props);

    this.setGender = this.setGender.bind(this);
  }

  setGender(value) {
    const { onStepValueChange } = this.props;

    onStepValueChange({ currentTarget: { value: value } }, "gender");
  }

  render() {
    const { gender, t } = this.props;
    const _constants = OnBoardService.constants.gender;
    return (
      <div className="gender-selector">
        <div
          className={`gender ${gender === _constants.male ? "selected" : ""}`}
          onClick={() => {
            this.setGender(_constants.male);
          }}
        >
          <div className="value">{t("wizard.gender.male")}</div>
        </div>
        <div
          className={`gender ${gender === _constants.female ? "selected" : ""}`}
          onClick={() => {
            this.setGender(_constants.female);
          }}
        >
          <div className="value">{t("wizard.gender.female")}</div>
        </div>
        <div
          className={`gender lg ${
            gender === _constants.other ? "selected" : ""
          }`}
          onClick={() => {
            this.setGender(_constants.other);
          }}
        >
          <div className="value">{t("wizard.gender.none")}</div>
        </div>
      </div>
    );
  }
}

export default translate("onboarding")(GenderSelector);
